import React, {useCallback, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../services/auth/auth'
import {initDb as _initDb} from '../../services/db/initDb'
import {resetDb as _resetDb} from '../../services/db/resetDb'
import {updateBuildWithUs as _updateBuildWithUs} from '../../services/db/updateBuildWithUs'
import {updateTextFormats} from '../../services/db/updateTextFormats'
import * as s from './AdminPage.module.scss'

export function AdminDevPage() {
  const {isDev} = useAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const navigate = useNavigate()
  const [hide, setHide] = useState<boolean>(true)

  const initDb = useCallback(async () => {
    try {
      setLoading(true)
      await _initDb()
    } catch (e) {
      console.error(e)
      setError((e as Error).message)
    } finally {
      setLoading(false)
    }
  }, [])

  const resetDb = useCallback(async () => {
    try {
      setLoading(true)
      if (confirm('This will erase any existing content. Are you sure?')) {
        await _resetDb()
      }
    } catch (e) {
      console.error(e)
      setError((e as Error).message)
    } finally {
      setLoading(false)
    }
  }, [])

  const updateFormats = useCallback(async () => {
    try {
      setLoading(true)
      await updateTextFormats()
    } catch (e) {
      console.error(e)
      setError((e as Error).message)
    } finally {
      setLoading(false)
    }
  }, [])

  const resetBuildWithUs = useCallback(async () => {
    try {
      setLoading(true)
      await _updateBuildWithUs()
    } catch (e) {
      console.error(e)
      setError((e as Error).message)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (isDev) {
      setHide(false)
    }
  }, [isDev])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isDev) {
        console.error(
          'Not authenticated. Please contact developer admin to gain access.',
        )
        navigate('/admin')
        return null
      }
    }, 2000)
    return () => clearTimeout(timeout)
  }, [isDev, navigate])

  if (hide) {
    return null
  }

  return (
    <article className={s.container}>
      {!!error && <small className={s.error}>{error}</small>}
      <button className={s.button} disabled={loading} onClick={updateFormats}>
        Update formats
      </button>
      <button
        className={s.button}
        disabled={loading}
        onClick={resetBuildWithUs}
      >
        Reset buildWithUs
      </button>
      <button className={s.button} disabled={loading} onClick={initDb}>
        Init db
      </button>
      <button className={s.button} disabled={loading} onClick={resetDb}>
        Reset db
      </button>
    </article>
  )
}
