import {ButtonSection} from '../../../lib/cms/src/@types/ButtonSection'
import {SectionType} from '../../../lib/cms/src/@types/SectionType'
import {TextContent} from '../../../lib/cms/src/@types/TextContent'
import {TextContentType} from '../../../lib/cms/src/@types/TextContentType'
import {TextFormat} from '../../../lib/cms/src/@types/TextFormat'

export function createButtonSection<T extends ButtonSection = ButtonSection>(
  text: TextContent['text'],
  optionals: {
    id?: T['id']
    format?: TextFormat
  } = {},
): T {
  const section = {
    type: SectionType.BUTTON,
    children: [
      {
        type: TextContentType.TEXT,
        text,
        ...(optionals.format ?? {}),
      } as TextContent,
    ],
  } as T

  if (!!optionals.id) {
    section.id = optionals.id
  }

  return section
}
