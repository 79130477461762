import classNames from 'classnames'
import React from 'react'
import {useAdmin} from '../../../lib/cms/src/services/admin/Admin.context'
import * as s from './SavingOverlay.module.scss'

export function SavingOverlay() {
  const {saving} = useAdmin()

  return <div className={classNames(s.overlay, {[s.show]: saving})} />
}
