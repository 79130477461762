import React from 'react'
import {useNavigate} from 'react-router-dom'
import {Editor} from '../../../../lib/cms/src'
import {fontWeights} from '../../../services/admin/fontWeights'
import {useAuth} from '../../../services/auth/auth'
import {StartPageContent} from '../../../types/StartPageContent'
import * as s from './BuildWithUs.module.scss'

interface Props {
  sections: StartPageContent['buildWithUs']
  onChange: (v: this['sections']) => any
}

export function BuildWithUs({sections, onChange}: Props) {
  const {isSignedIn} = useAuth()
  const navigate = useNavigate()

  return (
    <section className={s.container}>
      {!!sections && (
        <Editor
          readOnly={!isSignedIn}
          className={s.content}
          imageClassName={s.img}
          imageWrapperClassName={s.imgWrapper}
          textClassName={s.text}
          listClassName={s.list}
          buttonClassName={s.contactButton}
          sections={sections}
          onChange={onChange}
          as="div"
          fontWeights={fontWeights}
          onButtonClick={() => navigate('#kontakt')}
        />
      )}
    </section>
  )
}
