import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import SwiperCore, {Navigation, Pagination, Swiper as SwiperI} from 'swiper'
import 'swiper/modules/a11y/a11y.scss'
import 'swiper/modules/navigation/navigation.scss'
import 'swiper/modules/pagination/pagination.scss'
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react.js'
import 'swiper/swiper.scss'
import LeftArrow from '../../../assets/icons/left-arrow.svg'
import RightArrow from '../../../assets/icons/right-arrow.svg'
import AddIcon from '../../../assets/icons/streamline-icon-add-circle@40x40.svg'
import {useContentAdmin} from '../../../services/admin/useContentAdmin'
import {useAuth} from '../../../services/auth/auth'
import {createProject} from '../../../services/project/createProject'
import {BREAKPOINTS} from '../../../styles/_layout'
import {ProjectContent} from '../../../types/ProjectContent'
import {StartPageContent} from '../../../types/StartPageContent'
import {Project} from './Project/Project'
import * as s from './Projects.module.scss'

// install Swiper modules
SwiperCore.use([Pagination, Navigation])

interface Props {
  projects: StartPageContent['projects']
  onChange: (v: this['projects']) => any
}

export function Projects({projects: propProjects, onChange}: Props) {
  const {isSignedIn} = useAuth()
  const {onChange: update} = useContentAdmin<StartPageContent>('startPage')
  const [projects, setProjects] =
    useState<StartPageContent['projects']>(propProjects)

  useEffect(() => setProjects(propProjects), [propProjects])

  const onProjectChange = useCallback<(p: ProjectContent, i: number) => void>(
    (project, index) =>
      onChange(
        projects.reduce<ProjectContent[]>(
          (updated, _project, i) =>
            i === index ? [...updated, project] : [...updated, _project],
          [],
        ),
      ),
    [onChange, projects],
  )

  const add = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.preventDefault()
      const newProject = createProject()
      const _projects = [...projects, newProject]
      update('projects', _projects)
      setProjects(_projects)
      if (!!swiperRef) {
        swiperRef.slideTo(2)
      }
    },
    [projects, update],
  )

  const [removing, setRemoving] = useState<boolean>(false)
  const [removeProgress, setRemoveProgress] = useState<number>(0)
  const [slideBeforeRemoving, setSlideBeforeRemoving] = useState<number>()
  const [swiperRef, setSwiperRef] = useState<SwiperI>()

  const remove = useCallback<(index: number) => void>(
    (index) => {
      setRemoving(true)
      setSlideBeforeRemoving(swiperRef?.activeIndex)
      const _projects = projects.filter((_, i) => i !== index)
      update('projects', _projects)
      setProjects(_projects)
      setTimeout(() => setRemoving(false), 1000)
    },
    [projects, swiperRef?.activeIndex, update],
  )
  useEffect(() => {
    if (!removing && !!slideBeforeRemoving && !!swiperRef) {
      swiperRef.slideTo(slideBeforeRemoving)
    }
  }, [removing, slideBeforeRemoving, swiperRef])

  const removeProgressTimeout = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (removing && removeProgress < 1) {
      removeProgressTimeout.current = setTimeout(
        () => setRemoveProgress(removeProgress + 0.1),
        100,
      )
    } else {
      if (!!removeProgressTimeout.current) {
        clearTimeout(removeProgressTimeout.current)
      }
      setRemoveProgress(0)
    }
    return () => {
      if (!!removeProgressTimeout.current) {
        clearTimeout(removeProgressTimeout.current)
      }
    }
  }, [removeProgress, removing])

  const slidesPerView = useMemo(
    () => (window.innerWidth <= BREAKPOINTS.PHONE_MAX ? 1 : 3),
    [],
  )

  const nextElRef = useRef<HTMLButtonElement>(null)
  const previousElRef = useRef<HTMLButtonElement>(null)

  return (
    <section className={s.container}>
      {removing && (
        <div className={s.removing}>
          <div className={s.track} />
          <div
            className={s.progress}
            style={{maxWidth: `${100 * removeProgress}%`}}
          />
        </div>
      )}
      <Swiper
        onSwiper={setSwiperRef}
        slidesPerView={slidesPerView}
        spaceBetween={30}
        grabCursor={!isSignedIn}
        loop
        navigation={{
          nextEl: nextElRef.current,
          prevEl: previousElRef.current,
        }}
        onBeforeInit={(swiper) => {
          if (!!swiper.params.navigation) {
            ;(swiper.params.navigation as any).nextEl = nextElRef.current
            ;(swiper.params.navigation as any).prevEl = previousElRef.current
          }
        }}
        centeredSlides
        pagination={{
          clickable: true,
        }}
        className={s.swiper}
      >
        {!!projects &&
          !removing &&
          projects.map((project, index) => (
            <SwiperSlide key={`p_${index}`}>
              <Project
                project={project}
                onChange={(p) => onProjectChange(p, index)}
                onRemove={() => remove(index)}
              />
            </SwiperSlide>
          ))}
      </Swiper>

      <button ref={nextElRef} className={s.next}>
        <img src={RightArrow} alt="Right arrow" />
      </button>
      <button ref={previousElRef} className={s.previous}>
        <img src={LeftArrow} alt="Left arrow" />
      </button>

      {isSignedIn && (
        <div className={s.addContainer}>
          <button onClick={add}>
            <img src={AddIcon} alt="Kanppikon för att lägga till projekt" />
            <span>Lägg till projekt</span>
          </button>
        </div>
      )}
    </section>
  )
}
