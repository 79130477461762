import {css} from '@emotion/react'

const greyLighter = '#e2e5e8'
const greyLightest = '#f0f2f4'
const greyDark = '#424649'
const filterGreyLight =
  'invert(94%) sepia(6%) saturate(133%) hue-rotate(163deg) brightness(88%) contrast(89%)'
const filterGrey =
  'invert(73%) sepia(9%) saturate(138%) hue-rotate(169deg) brightness(91%) contrast(90%)'

export const editableImageContainer = css`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

export const editableImageUpload = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${greyLighter};
  background-image: linear-gradient(
    -45deg,
    ${greyLightest} 25%,
    rgba(0, 0, 0, 0) 25%,
    rgba(0, 0, 0, 0) 50%,
    ${greyLightest} 50%,
    ${greyLightest} 75%,
    rgba(0, 0, 0, 0) 75%,
    rgba(0, 0, 0, 0)
  );
  background-size: 40px 40px;
  outline: 3px dashed transparent;
  transition: all 250ms;
  pointer-events: none;
  overflow: hidden;

  img {
    width: auto;
    height: 1.5rem;
    max-width: 90%;
    max-height: 50%;
    padding: 0.5rem;
    transition: all 250ms;
    filter: ${filterGreyLight};
  }

  span {
    color: $grey;
    text-align: center;
    font-size: 0.85rem;
    line-height: 1;
    transition: all 250ms;
  }
`

export const editableImageUploadDragging = css`
  box-shadow: inset 0 0 50px 10px rgba(0, 0, 0, 0.1);
  background-color: ${greyLighter};
  background-image: linear-gradient(
    -45deg,
    white 25%,
    rgba(0, 0, 0, 0) 25%,
    rgba(0, 0, 0, 0) 50%,
    white 50%,
    white 75%,
    rgba(0, 0, 0, 0) 75%,
    rgba(0, 0, 0, 0)
  );
  outline: 3px dashed blue;

  img {
    filter: ${filterGrey};
  }
  span {
    color: ${greyDark};
  }
`

export const editableImageCaption = css`
  grid-column: 3 / 5;
  height: 90%;
`

export const editableImageImage = css`
  position: relative;
  z-index: 1;
`

export const editableImageDraggingIndicator = css`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: 3px dashed transparent;
  transition: all 250ms;
`

export const editableImageDragging = css`
  box-shadow: inset 0 0 50px 10px rgba(0, 0, 0, 0.2);
  outline: 3px dashed blue;
`
