@use '../../../styles/button';
@use '../../../styles/color';
@use '../../../styles/layout';

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @include layout.contentWidth;
  height: 100%;
}

.content {
  position: relative;
  display: grid;
  justify-content: center;
  grid-template-columns: [image] 2fr [text-1] 0.5fr [text-2] 0.5fr [text-3] 1fr;
  grid-template-rows: repeat(4, auto);
  width: 100%;
  padding-right: 3rem;
  background-color: color.$grey-lightest;
  border-radius: 8px;
  column-gap: 1rem;
  overflow: hidden;
  row-gap: 2rem;

  @include layout.phoneMax {
    display: grid;
    grid-template-columns: 0.75fr 1fr;
    padding-top: 50px;
    border-radius: 0;
    gap: 0.5rem;

    @include layout.horizontalContentPadding;
  }
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imgWrapper {
  justify-content: center;
}

.text {
  grid-column: text-1 / -1;
  text-align: left;

  @include layout.phoneMax {
    grid-column: 1 / -1;
  }

  @include layout.tabletMin {
    &:nth-child(2) {
      padding-top: 2rem;
    }
  }
}

.list {
  grid-column: text-1 / -1;
  padding-bottom: 2rem;
  list-style: none;
  counter-reset: muffins;
  padding-inline-start: 0;

  li {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    counter-increment: muffins;
  }
  li:before {
    padding-right: 1rem;
    color: color.$blue;
    font-size: 48px;
    font-weight: 800;
    content: '0' counter(muffins);
  }

  @include layout.tabletMin {
    margin-left: 2rem;
  }
  @include layout.phoneMax {
    grid-column: 1 / -1;
  }
}

:global(.buildwithus-heading),
:global(.buildwithus-body) {
  @include layout.tabletMin {
    margin-left: 2rem;
  }
}

:global(.buildwithus-img) {
  grid-column: image;
  grid-row: 1 / -1;

  @include layout.phoneMax {
    grid-column: 1 / -1;
    grid-row: 1;
    border-radius: 8px;
    overflow: hidden;
  }
}

.contactButton {
  grid-column: text-1;
  @include button.contactUsButton;
  @include layout.tabletMin {
    margin-bottom: 2rem;
    margin-left: 2rem;
  }
  @include layout.phoneMax {
    grid-column: 1 / -1;
  }
}

:global(.buildwithus-contact-img) {
  grid-column: text-2;
  width: 40px !important;
  height: 40px !important;
  margin-right: 0.5rem;
  margin-bottom: 2rem;
  margin-left: auto;

  img {
    object-fit: contain;
  }

  @include layout.phoneMax {
    grid-column: 1;
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
}

:global(.buildwithus-contact-value) {
  grid-column: text-3;
  margin-bottom: 2rem;

  @include layout.phoneMax {
    grid-column: 2;
    padding-top: 2rem;
    margin-bottom: 4rem;
  }
}
