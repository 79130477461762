/** @jsxImportSource @emotion/react */
import classNames from 'classnames'
import React from 'react'
import {RenderElementProps} from 'slate-react'
import {ListSection} from '../../@types/ListSection'
import {ListSectionType} from '../../@types/ListSectionType'
import {useGrid} from '../../services/section/useGrid'

interface Props extends Omit<RenderElementProps, 'element'> {
  element: ListSection
  className?: string
}

export function List(props: Props) {
  const {element: section, className, children, attributes} = props
  const grid = useGrid(section)

  return section.listType === ListSectionType.BULLETS ? (
    <ul
      className={classNames(className, section.id)}
      {...attributes}
      css={grid}
    >
      {children}
    </ul>
  ) : (
    <ol
      className={classNames(className, section.id)}
      {...attributes}
      css={grid}
    >
      {children}
    </ol>
  )
}
