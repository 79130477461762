import {css} from '@emotion/react'
import {
  toolbarButton,
  toolbarContainer,
  toolbarContainerBackgroundColor,
  toolbarContainerBorderRadius,
} from '../toolbars.style'

export const container = css`
  ${toolbarContainer};
  z-index: 100;
  align-items: center;

  &:after {
    position: absolute;
    bottom: -5px;
    left: calc(50% - 5px);
    width: 10px;
    height: 10px;
    background-color: black;
    transform: rotateZ(45deg);
    content: '';
  }
`

export const buttonWrapper = css`
  position: relative;
`

export const subButtonsContainer = css`
  position: absolute;
  bottom: calc(-100% - 8px);
  left: 50%;
  display: flex;
  z-index: 5;
  transform: translateX(-50%);
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  background-color: ${toolbarContainerBackgroundColor};
  border-radius: ${toolbarContainerBorderRadius};
`
export const subButton = css`
  ${toolbarButton};
  padding: 2px 0;
  margin-bottom: 0;
  font-size: 0.75em;
`
