import {css} from '@emotion/react'
import {COLOR} from '../../../../../../../../src/styles/_color'
import {toolbarButton, toolbarContainer} from '../../../toolbars.style'

export const button = css`
  ${toolbarButton};
  position: relative;
  left: 2px;
  z-index: 5;
  img {
    width: 8px;
    height: 8px;
    transform: rotateZ(-90deg);
  }
  span {
    padding-right: 4px;
  }
`

export const container = css`
  ${toolbarContainer};
  position: absolute;
  top: 0;
  left: 50px;
  display: block;
  z-index: 50;
  align-items: center;
  padding-left: 8px;
  margin-top: -8px;
  list-style-type: none;
  padding-inline-start: 0;

  li {
    width: 100%;
    margin: 0;
    color: ${COLOR.GREY};
    text-align: right;

    &:hover {
      color: black;
    }
  }
`

export const listButton = css`
  width: 100%;
  margin: 0;
`
