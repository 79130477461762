/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import React from 'react'
import {FontWeight} from '../../../../@types/FontWeight'
import {activeToolbarButton} from '../../toolbars.style'
import {subButton} from '../SelectionToolbar.style'

interface Props {
  weight: FontWeight
  activeWeight: FontWeight
  onClick: (weight: FontWeight) => void
}

export function FontWeightButton({weight, activeWeight, onClick}: Props) {
  return (
    <button
      css={[
        subButton,
        css`
          font-weight: ${weight};
        `,
        activeWeight === weight && activeToolbarButton,
      ]}
      onClick={(e) => {
        e.preventDefault()
        onClick(weight)
      }}
      tabIndex={0}
    >
      {weight}
    </button>
  )
}
