import { ListSection } from '../../../lib/cms/src/@types/ListSection'
import { SectionType } from '../../../lib/cms/src/@types/SectionType'
import { TextContent } from '../../../lib/cms/src/@types/TextContent'
import { TextContentType } from '../../../lib/cms/src/@types/TextContentType'
import { TextFormat } from '../../../lib/cms/src/@types/TextFormat'

export function createListSection<T extends ListSection = ListSection>(
  texts: string[],
  optionals: {
    listType?: T['listType']
    id?: T['id']
    format?: TextFormat
  } = {},
): T {
  const section = {
    type: SectionType.LIST,
    children: texts.map((t) => ({
      type: SectionType.LIST_ITEM,
      children: [
        {
          text: t,
          type: TextContentType.TEXT,
          ...(optionals.format ?? {}),
        } as TextContent,
      ],
    })),
  } as T
  if (!!optionals.listType) {
    section.listType = optionals.listType
  }

  if (!!optionals.id) {
    section.id = optionals.id
  }
  return section
}
