import { LinkContent } from '../../../lib/cms/src/@types/LinkContent'
import { SectionType } from '../../../lib/cms/src/@types/SectionType'
import { TextContent } from '../../../lib/cms/src/@types/TextContent'
import { TextContentType } from '../../../lib/cms/src/@types/TextContentType'
import { TextFormat } from '../../../lib/cms/src/@types/TextFormat'
import { TextSection } from '../../../lib/cms/src/@types/TextSection'
import { TextSectionType } from '../../../lib/cms/src/@types/TextSectionType'

export function createTextSection<T extends TextSection = TextSection>(
  text: TextContent['text'],
  optionals: {
    textType?: T['textType']
    id?: T['id']
    linkUrl?: LinkContent['url']
    format?: TextFormat
  } = {},
): T {
  const children: T['children'] = []
  if (!!optionals.linkUrl) {
    const content = {
      type: TextContentType.LINK,
      text,
      url: optionals.linkUrl,
      external: true,
      openInNewTab: true,
      ...(optionals.format ?? {}),
    } as LinkContent
    children.push(content)
  } else {
    children.push({
      type: TextContentType.TEXT,
      text,
      ...(optionals.format ?? {}),
    } as TextContent)
  }

  const section = {
    type: SectionType.TEXT,
    textType: optionals.textType ?? TextSectionType.PARAGRAPH,
    children,
  } as T

  if (!!optionals.id) {
    section.id = optionals.id
  }
  return section
}
