@use '../../styles/button';
@use '../../styles/admin';
@use '../../styles/layout';

.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: 500;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: layout.$headerHeight;
  background-color: white;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
}

.logoContainer {
  width: 100%;
  height: 100%;
  max-width: 250px;
  max-height: layout.$headerHeight;
  padding-right: 2rem;
  overflow: hidden;
}

.contactUsButton {

  @include button.contactUsButton;
}

.imgWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.signedIn {
  margin-top: admin.$height;
}
