/** @jsxImportSource @emotion/react */
import React, {useRef, useState} from 'react'
import {SketchPicker, SketchPickerProps} from 'react-color'
import {useSlate} from 'slate-react'
import uniqid from 'uniqid'
import {useOutsideClick} from '../../../services/dom/useOutsideClick'
import {container, picker, preview} from './ColorPicker.style'

interface Props extends Omit<SketchPickerProps, 'color'> {
  color: string | undefined
}

export function ColorPicker(props: Props) {
  const {color = '#000000'} = props
  const editor = useSlate()

  const [pickerVisible, setPickerVisible] = useState<boolean>(false)
  const containerId = useRef(uniqid()).current

  useOutsideClick(
    containerId,
    () => setPickerVisible(false),
    () => pickerVisible,
  )

  return (
    <div css={container} id={containerId}>
      <span
        css={preview}
        role="button"
        tabIndex={0}
        onKeyDown={() => null}
        onClick={() => {
          const selection = editor.selection
          setPickerVisible(!pickerVisible)
          if (!!selection) {
            // setTimeout(() => Transforms.setSelection(editor, selection), 2000)
          }
        }}
        style={{color}}
      >
        A
      </span>
      {pickerVisible && <SketchPicker {...props} css={picker} />}
    </div>
  )
}
