import { ImageSection } from '../../../lib/cms/src/@types/ImageSection'
import { SectionType } from '../../../lib/cms/src/@types/SectionType'
import { TextContent } from '../../../lib/cms/src/@types/TextContent'

export function createImageSection<T extends ImageSection = ImageSection>(
  optionals: { image?: T['image']; id?: T['id'] } = {},
): T {
  const section = {
    type: SectionType.IMAGE,
    children: [{ text: '' } as TextContent],
  } as T

  if (!!optionals.id) {
    section.id = optionals.id
  }

  if (!!optionals.image) {
    section.image = optionals.image
  }
  return section
}
