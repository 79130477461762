import {getDoc} from 'firebase/firestore'
import {ListSectionType} from '../../../lib/cms/src/@types/ListSectionType'
import {TextSectionType} from '../../../lib/cms/src/@types/TextSectionType'
import {COLOR} from '../../styles/_color'
import {StartPageContent} from '../../types/StartPageContent'
import {ContentsApi} from '../api/Contents.api'
import {createProject} from '../project/createProject'
import {createButtonSection} from '../section/createButtonSection'
import {createImageSection} from '../section/createImageSection'
import {createListSection} from '../section/createListSection'
import {createTextSection} from '../section/createTextSection'
import {typedDoc} from './typedDoc'

export async function initStartPage(
  overrides: (keyof StartPageContent)[] = [],
) {
  console.group('Start page')

  const id = 'startPage'
  const ref = typedDoc<StartPageContent>('contents', id)
  const snap = await getDoc(ref)

  let hasChanges = false
  let startPage: StartPageContent
  if (snap.exists()) {
    console.debug(
      'Start page exists, checking if any of its content is missing...',
    )
    startPage = snap.data()
  } else {
    console.debug('Creating start page...')
    startPage = {id: 'startPage'} as StartPageContent
  }

  if (!!startPage.hero && !overrides.includes('hero')) {
    console.debug('Start page -> Hero already exists. Skipping.')
  } else {
    hasChanges = true
    console.debug('Start page -> Creating hero...')
    startPage.hero = createHero()
  }

  if (!!startPage.lead && !overrides.includes('lead')) {
    console.debug('Start page -> Lead already exists. Skipping.')
  } else {
    hasChanges = true
    console.debug('Start page -> Creating lead...')
    startPage.lead = createLead()
  }

  if (!!startPage.businesses && !overrides.includes('businesses')) {
    console.debug('Start page -> Businesses already exists. Skipping.')
  } else {
    hasChanges = true
    console.debug('Start page -> Creating businesses...')
    startPage.businesses = createBusinesses()
  }

  if (!!startPage.buildWithUs && !overrides.includes('buildWithUs')) {
    console.debug('Start page -> Build with us already exists. Skipping.')
  } else {
    hasChanges = true
    console.debug('Start page -> Creating buildWithUs...')
    startPage.buildWithUs = createBuildWithUs()
  }

  if (!!startPage.projectsText && !overrides.includes('projectsText')) {
    console.debug('Start page -> Projects text already exists. Skipping.')
  } else {
    hasChanges = true
    console.debug('Start page -> Creating projectsText...')
    startPage.projectsText = createProjectsText()
  }

  if (!!startPage.projects && !overrides.includes('projects')) {
    console.debug('Start page -> Projects already exists. Skipping.')
  } else {
    hasChanges = true
    console.debug('Start page -> Creating projects...')
    startPage.projects = createProjects()
  }

  if (!!startPage.contact && !overrides.includes('contact')) {
    console.debug('Start page -> Contact already exists. Skipping.')
  } else {
    hasChanges = true
    console.debug('Start page -> Creating contact...')
    startPage.contact = createContact()
  }

  if (hasChanges) {
    console.debug('Persisting changes to Start page...')
    console.log(startPage)
    await ContentsApi.save('startPage', startPage)
  }

  console.debug('Start page done!')
  console.groupEnd()
  return
}

function createHero(): StartPageContent['hero'] {
  return [
    createImageSection<StartPageContent['hero'][0]>({id: 'hero-img'}),
    createTextSection<StartPageContent['hero'][1]>(
      'Vilket blir ditt nästa projekt?',
      {
        textType: TextSectionType.H1,
        id: 'hero-h1',
        format: {color: '#ffffff'},
      },
    ),
  ]
}

function createLead(): StartPageContent['lead'] {
  return [
    createTextSection<StartPageContent['lead'][0]>('Du drömmer, vi bygger!', {
      textType: TextSectionType.HEADING,
      id: 'lead-heading',
      format: {color: COLOR.BLUE},
    }),
    createTextSection<StartPageContent['lead'][1]>(
      'Vi utför uppdrag inom bygg, inredning och entrepenad',
      {
        textType: TextSectionType.PARAGRAPH,
        id: 'lead-small',
        format: {
          fontWeight: 600,
        },
      },
    ),
  ]
}

function createBusinesses(): StartPageContent['businesses'] {
  return [
    createImageSection<StartPageContent['businesses'][0]>({
      id: 'businesses-construction-img',
    }),
    createTextSection<StartPageContent['businesses'][1]>('Bygg', {
      textType: TextSectionType.PARAGRAPH,
      id: 'businesses-construction-text',
      format: {bold: true},
    }),
    createImageSection<StartPageContent['businesses'][2]>({
      id: 'businesses-decoration-img',
    }),
    createTextSection<StartPageContent['businesses'][3]>('Inredning', {
      textType: TextSectionType.PARAGRAPH,
      id: 'businesses-decoration-text',
      format: {bold: true},
    }),
    createImageSection<StartPageContent['businesses'][4]>({
      id: 'businesses-contract-img',
    }),
    createTextSection<StartPageContent['businesses'][5]>('Entrepenad', {
      textType: TextSectionType.PARAGRAPH,
      id: 'businesses-contract-text',
      format: {bold: true},
    }),
  ]
}

function createBuildWithUs(): StartPageContent['buildWithUs'] {
  return [
    createImageSection<StartPageContent['buildWithUs'][0]>({
      id: 'buildwithus-img',
    }),
    createTextSection<StartPageContent['buildWithUs'][1]>(
      'Bygg något med oss!',
      {
        textType: TextSectionType.HEADING,
        id: 'buildwithus-heading',
        format: {color: COLOR.BLUE, fontWeight: 700},
      },
    ),
    createTextSection<StartPageContent['buildWithUs'][2]>(
      'Låt oss vara med på ditt nästa projekt! Vi hjälper dig med ditt nästa projekt inom bygg, inredning eller entreprenad. Kontakta oss idag för offert!',
      {
        textType: TextSectionType.PARAGRAPH,
        id: 'buildwithus-body',
        format: {fontWeight: 500},
      },
    ),
    createListSection<StartPageContent['buildWithUs'][3]>(
      [
        'Kontakta oss',
        'Vi återkopplas med estimat och tidsplan',
        'Våra proffsiga och certifierade Hantverkare utför jobbet',
      ],
      {
        listType: ListSectionType.NUMBERED,
        id: 'buildwithus-list',
        format: {fontWeight: 500},
      },
    ),
    createButtonSection<StartPageContent['buildWithUs'][4]>('Kontakta oss', {
      id: 'buildwithus-contact-button',
      format: {color: 'white', fontWeight: 500},
    }),
    createImageSection<StartPageContent['buildWithUs'][5]>({
      id: 'buildwithus-contact-img',
    }),
    createTextSection<StartPageContent['buildWithUs'][6]>('072-2439085', {
      textType: TextSectionType.PARAGRAPH,
      id: 'buildwithus-contact-value',
      format: {color: COLOR.BLUE, fontWeight: 700},
    }),
  ]
}

function createProjectsText(): StartPageContent['projectsText'] {
  return [
    createTextSection<StartPageContent['projectsText'][0]>('Tidigare projekt', {
      textType: TextSectionType.HEADING,
      id: 'projectstext-heading',
      format: {color: COLOR.BLUE},
    }),
    createTextSection<StartPageContent['projectsText'][1]>(
      'Här kan du se några av våra tidigare projekt.',
      {
        textType: TextSectionType.PARAGRAPH,
        id: 'projectstext-small',
        format: {
          fontWeight: 600,
        },
      },
    ),
  ]
}

function createProjects(): StartPageContent['projects'] {
  return [
    createProject('Inredning', 'Botanika, Uppsala'),
    createProject('Markarbete', 'Privatperson, Uppsala'),
    createProject('Renovering, vattenskada', 'Privatperson, Gävle'),
    createProject('Ett projekt', 'Bygg, Stockholm'),
  ]
}

function createContact(): StartPageContent['contact'] {
  return [
    createImageSection({id: 'contact-phone-img'}),
    createTextSection('Ring oss', {
      textType: TextSectionType.HEADING,
      id: 'contact-phone-heading',
      format: {color: '#ffffff'},
    }),
    createTextSection('072-2439085', {
      textType: TextSectionType.PARAGRAPH,
      id: 'contact-phone-value',
      format: {color: '#ffffff'},
    }),
    createImageSection({id: 'contact-email-img'}),
    createTextSection('E-post', {
      textType: TextSectionType.HEADING,
      id: 'contact-email-heading',
      format: {color: '#ffffff'},
    }),
    createTextSection('goran@joelssonbygg.se', {
      textType: TextSectionType.PARAGRAPH,
      id: 'contact-email-value',
      linkUrl: 'mailto:goran@joelssonbygg.se',
      format: {color: '#ffffff'},
    }),
    createImageSection({id: 'contact-address-img'}),
    createTextSection('Här finns vi', {
      textType: TextSectionType.HEADING,
      id: 'contact-address-heading',
      format: {color: '#ffffff'},
    }),
    createTextSection('Uppsala, Sverige', {
      textType: TextSectionType.PARAGRAPH,
      id: 'contact-address-value',
      format: {color: '#ffffff'},
    }),
  ]
}
