import {isFinite} from 'lodash'
import {Editor, Text as SlateText, Transforms} from 'slate'
import {Section} from '../../@types/Section'
import {TextContent} from '../../@types/TextContent'
import {TextFormat} from '../../@types/TextFormat'

const {isText} = SlateText

export const TextService = {
  getFormat<T extends keyof TextFormat>(
    editor: Editor,
    format: T,
  ): TextFormat[T] | undefined {
    const fragments = editor.getFragment()
    if (!fragments.length) {
      return
    }
    const textContent = Utils.getFragmentTextContent(fragments[0])

    if (
      !!textContent &&
      Object.getOwnPropertyNames(textContent).includes(format)
    ) {
      return textContent[format]
    }
  },

  setFormat<T extends keyof TextFormat>(
    editor: Editor,
    format: T,
    value: TextFormat[T],
  ): void {
    Transforms.setNodes(editor, {[format]: value}, {match: isText, split: true})
  },

  isFormatActive(editor: Editor, format: keyof TextFormat): boolean {
    const [match] = Editor.nodes(editor, {
      match: (n) => {
        const content = n as TextContent
        if (!!content) {
          return !!content[format] ?? false
        }
        return false
      },
      universal: true,
    })
    return !!match
  },

  toggleFormat(editor: Editor, format: keyof TextFormat): void {
    const isActive = TextService.isFormatActive(editor, format)

    Transforms.setNodes(
      editor,
      {[format]: !isActive},
      {match: isText, split: true},
    )
  },

  isItalic(editor: Editor): boolean {
    const [match] = Editor.nodes(editor, {
      match: (n) => {
        const content = n as TextContent
        if (!!content) {
          return content.fontStyle === 'italic'
        }
        return false
      },
    })
    return !!match
  },

  toggleItalic(editor: Editor): void {
    const isActive = this.isItalic(editor)
    Transforms.setNodes<TextContent>(
      editor,
      {fontStyle: isActive ? 'normal' : 'italic'},
      {match: isText, split: true},
    )
  },

  isBold(editor: Editor): boolean {
    const fontWeight = Number(this.getFormat(editor, 'fontWeight'))
    return (
      (isFinite(fontWeight) && fontWeight > 400) ||
      TextService.isFormatActive(editor, 'bold')
    )
  },
}

class Utils {
  static getFragmentTextContent(
    fragment: Section | TextContent,
  ): TextContent | null {
    const keys = Object.getOwnPropertyNames(fragment)
    if (!keys.includes('children')) {
      if (!keys.includes('text')) {
        console.warn('Unable to find text property')
        return null
      }
      return fragment as TextContent
    } else {
      const section = fragment as Section
      if (!section.children.length) {
        console.warn("Section has no kids, can't get its TextContent")
        return null
      }
      return (
        section.children
          .map((c) => this.getFragmentTextContent(c))
          .find((c) => !!c) ?? null
      )
    }
  }
}
