import { Model } from '@joelssonbygg/shared/types/Model'
import { doc, writeBatch } from 'firebase/firestore'
import { getDb } from '../firebase/firebase'

export async function chunkBatch<T extends Model>(
  objects: T[],
  collectionName: string,
  operation: 'set' | 'delete' = 'set',
) {
  const chunks = objects.reduce<T[][]>((r, a, i) => {
    if (i % 499 === 0) {
      r.push([a])
    } else {
      r[r.length - 1].push(a)
    }
    return r
  }, [])

  const db = getDb()

  await Promise.all(
    chunks.map(async (chunk) => {
      const batch = writeBatch(db)
      chunk.forEach((obj) => {
        if (operation === 'set') {
          const ref = !!obj.id
            ? doc(db, collectionName, obj.id)
            : doc(db, collectionName)
          batch.set(ref, obj, { merge: true })
        } else if (operation === 'delete') {
          if (!obj.id) {
            console.warn(`Object missing id, will not be deleted`, obj)
          } else {
            const ref = doc(db, collectionName, obj.id)
            batch.delete(ref)
          }
        }
      })
      await batch.commit()
      console.debug(`Committed batch of ${chunk.length} documents`)
    }),
  )
  console.debug(
    `Done committing ${chunks.length} chunks (${objects.length} documents)`,
  )
}
