import {
  Auth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut as _signOut,
  User,
} from 'firebase/auth'
import * as React from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { getAuth } from '../firebase/firebase'

export interface AuthContext {
  user: User | null
  isSignedIn: boolean
  signIn: (email: string, password: string) => Promise<void>
  signOut: () => Promise<void>
  readonly auth: Auth | undefined
  isDev: boolean
}

export const AuthContext = createContext<AuthContext>({} as AuthContext)

const devEmails = ['mikael@whatwedo.se', 'mikael.edgren@whatwedo.se']

export function AuthProvider({ children }: any) {
  const [user, setUser] = useState<User | null>(null)
  const [auth, setAuth] = useState<Auth>()
  const isSignedIn = useMemo<boolean>(() => !!user, [user])

  const isDev = useMemo<boolean>(
    () => !!user?.email && devEmails.includes(user.email),
    [user],
  )

  useEffect(() => {
    setAuth(getAuth())
  }, [])

  useEffect(() => {
    if (!!auth) {
      onAuthStateChanged(auth, (u) => setUser(u))
    }
  }, [auth])

  const signIn = async (email: string, password: string) => {
    if (!!auth) {
      try {
        await signInWithEmailAndPassword(auth, email, password)
      } catch (e) {
        console.error(e)
        if (e instanceof Error) {
          throw e.message
        }
      }
    }
  }

  const signOut = useCallback(async () => {
    if (!!auth) {
      await _signOut(auth)
      setUser(null)
    }
  }, [auth])

  return (
    <AuthContext.Provider
      value={{
        user,
        isSignedIn,
        signIn,
        signOut,
        auth,
        isDev,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth(): AuthContext {
  return useContext<AuthContext>(AuthContext)
}
