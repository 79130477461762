import { collection, deleteDoc, getDocs } from 'firebase/firestore'
import { getAuth } from '../firebase/firebase'
import { chunkBatch } from './chunkBatch'
import { typedCollection } from './typedCollection'

export async function clearCollection(
  collectionName: string,
  subCollectionNames: string[] = ['history'],
): Promise<void> {
  console.group(`clearCollection ${collectionName}`)
  const auth = getAuth()
  if (!auth.currentUser) {
    throw new Error('Must be signed in')
  }
  const _collection = typedCollection(collectionName)
  const snapshot = await getDocs(_collection)

  if (snapshot.empty) {
    console.debug(`Collection ${collectionName} is already empty`)
    console.groupEnd()
  }

  await Promise.all(
    subCollectionNames.map(async (subCollectionName) =>
      snapshot.docs.map(async (d) => {
        const subSnapshot = await getDocs(collection(d.ref, subCollectionName))
        await Promise.all(subSnapshot.docs.map(async (h) => deleteDoc(h.ref)))
      }),
    ),
  )
  const docs = snapshot.docs.map((d) => d.data())
  await chunkBatch(docs, collectionName, 'delete')
  console.debug(`Collection ${collectionName} cleared`)
  console.groupEnd()
}
