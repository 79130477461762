import {css} from '@emotion/react'
import {SerializedStyles} from '@emotion/serialize'
import {useMemo} from 'react'
import {Grid} from '../../@types/Grid'
import {Section} from '../../@types/Section'

export function useGrid(section: Section): SerializedStyles | false {
  return useMemo(
    () => !!section.grid && toGridCss(section.grid),
    [section.grid],
  )
}

function toGridCss(grid: Grid): SerializedStyles {
  return css`
    grid-column: ${grid.columnStart} / ${grid.columnEnd};
    grid-row: ${grid.rowStart} / ${grid.rowEnd};
  `
}
