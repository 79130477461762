export function inTreeOfId(el: HTMLElement, id: string): boolean {
  if (el.id === id) {
    return true
  }

  const parentEl = el.parentElement
  if (!parentEl) {
    return false
  }
  return inTreeOfId(parentEl, id)
}
