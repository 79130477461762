import {getDoc} from 'firebase/firestore'
import {HeaderContent} from '../../types/HeaderContent'
import {ContentsApi} from '../api/Contents.api'
import {createButtonSection} from '../section/createButtonSection'
import {createImageSection} from '../section/createImageSection'
import {typedDoc} from './typedDoc'

export async function initHeader() {
  console.group('Header')
  const ref = typedDoc<HeaderContent>(ContentsApi.COLLECTION, 'header')
  const snap = await getDoc(ref)

  let hasChanges = false
  let header: HeaderContent
  if (snap.exists()) {
    console.debug('Header exists, checking if any of its content is missing...')
    header = snap.data()
  } else {
    console.debug('Creating Header...')
    header = {id: 'header'} as HeaderContent
  }

  if (!!header.logo) {
    console.debug('Header -> Logo already exists. Skipping.')
  } else {
    hasChanges = true
    console.debug('Header -> Creating logo...')
    header.logo = [createImageSection({id: 'header-logo'})]
    header.contactUs = [
      createButtonSection('Kontakta oss', {
        id: 'header-contact-us',
        format: {color: 'white', fontWeight: 500},
      }),
    ]
  }

  if (hasChanges) {
    console.debug('Persisting changes to Header...')
    console.log(header)
    await ContentsApi.save('header', header)
  }

  console.debug('Header done!')
  console.groupEnd()
  return
}
