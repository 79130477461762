/** @jsxImportSource @emotion/react */
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Editor, Range} from 'slate'
import {useSlate} from 'slate-react'
import {FontWeight} from '../../../@types/FontWeight'
import {Section} from '../../../@types/Section'
import {TextService} from '../../../services/text/text.service'
import {Portal} from '../../Portal/Portal'
import {ColorPicker} from '../ColorPicker/ColorPicker'
import {activeToolbarButton, toolbarButton} from '../toolbars.style'
import {FontWeightSelect} from './FontWeightSelect/FontWeightSelect'
import {container} from './SelectionToolbar.style'

interface Props {
  sections: Section[]
  fontWeights?: FontWeight[]
}

export function SelectionToolbar({sections, fontWeights}: Props) {
  const ref = useRef<HTMLDivElement | null>(null)
  const editor = useSlate()

  const [visible, setVisible] = useState<boolean>(false)
  const [opacity, setOpacity] = useState<0 | 1>(0)
  const [top, setTop] = useState<number>(0)
  const [left, setLeft] = useState<number>(0)

  const [isItalic, setIsItalic] = useState<boolean>(false)
  const [isUppercase, setIsUppercase] = useState<boolean>(false)
  const [color, setColor] = useState<string>()

  const updateButtonStates = useCallback(() => {
    setIsItalic(TextService.isItalic(editor))
    setIsUppercase(TextService.isFormatActive(editor, 'uppercase'))
    setColor(TextService.getFormat(editor, 'color'))
  }, [editor])

  const show = useCallback(() => {
    const barEl = ref.current
    if (!!barEl) {
      const domSelection = window.getSelection()
      const domRange = domSelection?.getRangeAt(0)
      const rect = domRange?.getBoundingClientRect()

      if (!!rect && rect.width > 0) {
        updateButtonStates()
        setTop(rect.top + window.scrollY - barEl.offsetHeight - 10)
        setLeft(
          rect.left + window.scrollX - barEl.offsetWidth / 2 + rect.width / 2,
        )
        setVisible(true)
      }
    }
  }, [updateButtonStates])

  const hide = useCallback(() => {
    setVisible(false)
  }, [])

  useEffect(() => {
    const {selection} = editor
    const barEl = ref.current
    if (
      !barEl ||
      !selection ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      hide()
    } else {
      show()
    }
  }, [editor.selection, editor.children, sections, show, hide])

  useEffect(() => {
    if (visible) {
      setOpacity(1)
    } else {
      const timeout = setTimeout(() => setOpacity(0), 200)
      return () => clearTimeout(timeout)
    }
  }, [visible])

  return (
    <Portal>
      <div
        ref={ref}
        css={container}
        style={{
          left,
          top,
          opacity,
          pointerEvents: visible ? 'all' : 'none',
        }}
      >
        <FontWeightSelect weights={fontWeights} />

        <button
          css={[toolbarButton, isItalic && activeToolbarButton]}
          onClick={() => {
            TextService.toggleItalic(editor)
            updateButtonStates()
          }}
        >
          <i>I</i>
        </button>
        <button
          css={[toolbarButton, isUppercase && activeToolbarButton]}
          onClick={() => {
            TextService.toggleFormat(editor, 'uppercase')
            updateButtonStates()
          }}
        >
          TT
        </button>
        <ColorPicker
          color={color}
          onChangeComplete={(c) => {
            TextService.setFormat(editor, 'color', c.hex)
            updateButtonStates()
          }}
        />
      </div>
    </Portal>
  )
}
