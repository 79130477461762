import { DbOperationMeta } from '@joelssonbygg/shared/types/DbOperationMeta'
import { Model } from '@joelssonbygg/shared/types/Model'
import { getDoc, setDoc } from 'firebase/firestore'
import {
  getDownloadURL,
  ref,
  StringFormat,
  uploadString,
} from 'firebase/storage'
import { cloneDeep, get } from 'lodash'
import { Image } from '../../../lib/cms/src/@types/Image'
import { getFileContentTypeFromExtension } from '../../../lib/cms/src/services/image/getFileContentTypeFromExtension'
import { typedDoc } from '../db/typedDoc'
import { getAuth, getStorage } from '../firebase/firebase'

export class ContentsApi {
  static readonly COLLECTION = 'contents'

  static async get<T extends Model>(id: string): Promise<T | null> {
    const ref = typedDoc<T>(this.COLLECTION, id)
    const snap = await getDoc(ref)
    if (snap.exists()) {
      return snap.data()
    } else {
      console.warn('not found')
      return null
    }
  }

  static async save<T extends Model>(id: string, contents: T): Promise<void> {
    const auth = getAuth()
    if (!auth.currentUser) {
      throw new Error('Not authenticated')
    }

    const toSave: T = cloneDeep(contents)
    const paths = this.identifyLocalImageUrls(toSave)
    for (let i = 0; i < paths.length; i++) {
      const path = paths[i]
      const image = get(toSave, path)
      image.url = await this.uploadAndGetUrl(image)
    }
    const dbOperationMeta: DbOperationMeta = {
      userId: auth.currentUser.uid,
    }
    await setDoc(typedDoc<T>(this.COLLECTION, id), {
      ...toSave,
      dbOperationMeta,
    })
  }

  private static identifyLocalImageUrls<T extends Model>(
    contents: T,
    path = '',
  ): string[] {
    let paths: string[] = []
    Object.entries(contents).map(([field, value]) => {
      if (
        field === 'image' &&
        Object.getOwnPropertyNames(value).includes('url') &&
        this.isLocalUrl(value.url)
      ) {
        paths.push(`${path}${field}`)
      } else if (Array.isArray(value)) {
        value.forEach((v, i) => {
          const _paths = this.identifyLocalImageUrls(
            v,
            `${path}${field}[${i}].`,
          )
          paths = [...paths, ..._paths]
        })
      } else if (typeof value === 'object') {
        const _paths = this.identifyLocalImageUrls(value, `${path}${field}.`)
        paths = [...paths, ..._paths]
      }
    })
    return paths
  }

  private static async uploadAndGetUrl(image: Image): Promise<string> {
    const storage = getStorage()
    const storageRef = ref(storage, image.fileName)
    const contentType = getFileContentTypeFromExtension(image.fileName)
    await uploadString(storageRef, image.url, StringFormat.DATA_URL, {
      contentType,
    })
    return await getDownloadURL(storageRef)
  }

  private static isLocalUrl(url: string) {
    return !this.isRemoteUrl(url)
  }

  private static isRemoteUrl(url: string) {
    return (
      !!url &&
      (url.substring(0, 8) === 'https://' || url.substring(0, 7) === 'http://')
    )
  }
}
