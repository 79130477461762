@use '../../styles/color';
@use '../../styles/layout';
@use '../../styles/admin';

.container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: 2000;
  justify-content: center;
  width: 100vw;
  height: admin.$height;
  background-color: black;
}

.content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include layout.contentWidth;
  gap: 0.5rem;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: color.$grey-lighter;

    &:disabled {
      cursor: not-allowed;
      color: color.$grey-dark;
    }

    &:not(:disabled):hover {
      color: white;
    }
  }
}

.signOut {
  margin-right: 0;
  margin-left: auto;
}
