/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import classNames from 'classnames'
import React, {MouseEventHandler, useCallback} from 'react'
import {RenderElementProps} from 'slate-react'
import {ButtonSection} from '../../@types/ButtonSection'
import {OnButtonSectionClick} from '../../@types/OnButtonSectionClick'
import {useGrid} from '../../services/section/useGrid'

interface Props extends RenderElementProps {
  element: ButtonSection
  onClick: OnButtonSectionClick
  className?: string
  readonly: boolean
}

export function Button(props: Props) {
  const {
    element: section,
    readonly,
    onClick,
    children,
    attributes,
    className,
  } = props
  const grid = useGrid(section)

  const _onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      if (readonly) {
        e.preventDefault()
        onClick(section.id)
      } else {
        e.preventDefault()
      }
    },
    [onClick, readonly, section.id],
  )

  return (
    <button
      onClick={_onClick}
      css={[
        grid,
        !readonly &&
          css`
            cursor: text;
          `,
      ]}
      className={classNames(className, section.id)}
      {...attributes}
    >
      {children}
    </button>
  )
}
