import { ProjectContent } from '../../types/ProjectContent'
import { createImageSection } from '../section/createImageSection'
import { createTextSection } from '../section/createTextSection'

export function createProject(
  title = 'Rubrik',
  text = 'Beskrivning',
): ProjectContent {
  return {
    sections: [
      createImageSection(),
      createTextSection(title, { format: { fontWeight: 600 } }),
      createTextSection(text, { format: { fontWeight: 800 } }),
    ],
  }
}
