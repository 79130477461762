@use '../../../../styles/color';
@use '../../../../styles/layout';

$width: 100%;

.container {
  position: relative;
}

.removeButton {
  position: absolute;
  top: 12px;
  right: 20px;
  display: flex;
  z-index: 2000;
  justify-content: flex-start;
  align-items: center;
  padding: 6px;
  background-color: rgba(255, 255, 255, 0.4);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 250ms;

  img {
    height: 18px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
}

.content {
  display: grid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  grid-template-rows: auto min-content min-content;
  grid-template-columns: 1fr;
  width: $width;
  height: 300px;
  min-width: $width;
  margin-bottom: 40px;
  border-radius: 8px;
  overflow: hidden;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  &:after {
    z-index: 2;
    grid-column: 1;
    grid-row: 2 / -1;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    content: '';
    backdrop-filter: blur(5px);
  }
}

.imgWrapper {
  grid-column: 1;
  grid-row: 1 / -1;
  width: $width;
  height: 300px;
  max-height: 100%;
}

.img {
  width: 100%;
  height: 100%;
  max-height: 300px;
  object-fit: cover;
}

.text {
  margin: 0;

  &:nth-child(2) {
    z-index: 3;
    grid-column: 1;
    grid-row: 2;
    padding: 1rem 1rem 0.5rem;
  }
  &:nth-child(3) {
    z-index: 3;
    grid-column: 1;
    grid-row: 3;
    padding: 0 1rem 1.5rem;
    font-size: 0.75rem;
  }
}
