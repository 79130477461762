/** @jsxImportSource @emotion/react */
import React, {useMemo} from 'react'
import {useSlate} from 'slate-react'
import {TextSection} from '../../../../@types/TextSection'
import {TextSectionType} from '../../../../@types/TextSectionType'
import CenterAlign from '../../../../assets/icons/streamline-icon-paragraph-center-align@16x16.svg'
import JustifiedAlign from '../../../../assets/icons/streamline-icon-paragraph-justified-align@16x16.svg'
import LeftAlign from '../../../../assets/icons/streamline-icon-paragraph-left-align@16x16.svg'
import RightAlign from '../../../../assets/icons/streamline-icon-paragraph-right-align@16x16.svg'
import {SectionService} from '../../../Section/Section.service'
import {activeToolbarButton, toolbarButton} from '../../toolbars.style'
import {TextSectionTypeSelect} from './TextSectionTypeSelect/TextSectionTypeSelect'

interface Props {
  section: TextSection
  onChange: () => any
}

export function TextSectionToolbar({section, onChange}: Props) {
  const editor = useSlate()

  const textAlign = useMemo<TextSection['textAlign']>(() => {
    if (!!section.textAlign) {
      return section.textAlign
    }
    return section.textType === TextSectionType.BLOCK_QUOTE ? 'center' : 'left'
  }, [section.textAlign, section.textType])

  return (
    <>
      <TextSectionTypeSelect
        section={section}
        onChange={() => setTimeout(() => onChange(), 100)}
      />
      <button
        css={[toolbarButton, textAlign === 'left' && activeToolbarButton]}
        onClick={() => {
          SectionService.setTextSectionAlign(editor, 'left')
          onChange()
        }}
      >
        <img src={LeftAlign} alt="Left align" />
      </button>
      <button
        css={[toolbarButton, textAlign === 'center' && activeToolbarButton]}
        onClick={() => {
          SectionService.setTextSectionAlign(editor, 'center')
          onChange()
        }}
      >
        <img src={CenterAlign} alt="Center align" />
      </button>
      <button
        css={[toolbarButton, textAlign === 'right' && activeToolbarButton]}
        onClick={() => {
          SectionService.setTextSectionAlign(editor, 'right')
          onChange()
        }}
      >
        <img src={RightAlign} alt="Right align" />
      </button>
      <button
        css={[toolbarButton, textAlign === 'justify' && activeToolbarButton]}
        onClick={() => {
          SectionService.setTextSectionAlign(editor, 'justify')
          onChange()
        }}
      >
        <img src={JustifiedAlign} alt="Justified align" />
      </button>
    </>
  )
}
