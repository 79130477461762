import {css} from '@emotion/react'
import {COLOR} from '../../../../../../src/styles/_color'
import {toolbarButton} from '../toolbars.style'

export const container = css`
  ${toolbarButton};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 0 ;
`

export const preview = css`
  text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.15),
    1px -1px 0 rgba(255, 255, 255, 0.15), -1px 1px 0 rgba(255, 255, 255, 0.15),
    1px 1px 0 rgba(255, 255, 255, 0.15);
  margin-bottom: 1px;
`

export const smallBox = css`
  width: 14px;
  height: 14px;
  border: 1px solid ${COLOR.GREY};
  border-radius: 3px;
  &:hover {
    border-color: ${COLOR.GREY_LIGHTER};
  }
`

export const smallBoxContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-top: 2px;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: black;
  }
`

export const picker = css`
  position: absolute;
  bottom: 20px;
`
