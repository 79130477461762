import classNames from 'classnames'
import * as React from 'react'
import {Route, Routes} from 'react-router-dom'
import {AdminBar} from './components/AdminBar/AdminBar'
import {Footer} from './components/Footer/Footer'
import {Header} from './components/Header/Header'
import {SavingOverlay} from './components/SavingOverlay/SavingOverlay'
import {AdminDevPage} from './pages/AdminDevPage/AdminDevPage'
import {AdminPage} from './pages/AdminPage/AdminPage'
import {StartPage} from './pages/StartPage/StartPage'
import {useAuth} from './services/auth/auth'

export function App() {
  const {isSignedIn} = useAuth()
  return (
    <div className="App">
      {isSignedIn && <AdminBar />}
      {isSignedIn && <SavingOverlay />}
      <Header />
      <main className={classNames({signedIn: isSignedIn})}>
        <Routes>
          <Route path="/" element={<StartPage />} />
          <Route path="/admin" element={<AdminPage />} />
          {isSignedIn && <Route path="/admin/dev" element={<AdminDevPage />} />}
        </Routes>
      </main>
      <Footer />
    </div>
  )
}
