/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import React, {useMemo, useRef} from 'react'
import {RenderLeafProps} from 'slate-react/dist/components/editable'
import {LinkContent} from '../../@types/LinkContent'

export function Text(props: RenderLeafProps) {
  const {leaf: text, attributes, children} = props
  const ref = useRef<HTMLElement>(null)

  const style = useMemo(
    () => css`
      padding: 0;
      color: ${text.color};
      text-transform: ${!!text.uppercase ? 'uppercase' : undefined};
      font-style: ${text.fontStyle};
      font-weight: ${!!text.fontWeight
        ? text.fontWeight
        : !!text.bold
        ? 'bold'
        : undefined};
    `,
    [text.bold, text.color, text.fontStyle, text.fontWeight, text.uppercase],
  )

  if (text.type === 'LINK') {
    const link = text as unknown as LinkContent
    return (
      <a href={link.url} title={link.text} css={style} {...attributes}>
        {children}
      </a>
    )
  } else {
    return (
      <span {...attributes} css={style} ref={ref}>
        {children}
      </span>
    )
  }
}
