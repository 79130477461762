import {getDoc, updateDoc} from 'firebase/firestore'
import {Section} from '../../../lib/cms/src/@types/Section'
import {TextContent} from '../../../lib/cms/src/@types/TextContent'
import {FooterContent} from '../../types/FooterContent'
import {StartPageContent} from '../../types/StartPageContent'
import {typedDoc} from './typedDoc'

export async function updateTextFormats() {
  console.group('updateTextFormats')
  const startPageRef = typedDoc<StartPageContent>('contents', 'startPage')
  const startPageSnap = await getDoc(startPageRef)
  const footerRef = typedDoc<FooterContent>('contents', 'footer')
  const footerSnap = await getDoc(footerRef)

  const startPage = startPageSnap.data()
  const footer = footerSnap.data()
  if (!!startPage && !!footer) {
    const heroH1 = getText(startPage.hero[1])
    heroH1.fontWeight = 800

    const leadHeading = getText(startPage.lead[0])
    leadHeading.fontWeight = 700
    const leadSmall = getText(startPage.lead[1])
    leadSmall.fontWeight = 500

    const businessesConstruction = getText(startPage.businesses[0])
    businessesConstruction.fontWeight = 700
    const businessesDecoration = getText(startPage.businesses[1])
    businessesDecoration.fontWeight = 700
    const businessesContract = getText(startPage.businesses[2])
    businessesContract.fontWeight = 700

    const buildWithUsHeading = getText(startPage.buildWithUs[1])
    buildWithUsHeading.fontWeight = 700
    const buildWithUsBody = getText(startPage.buildWithUs[2])
    buildWithUsBody.fontWeight = 500
    startPage.buildWithUs[3].children.forEach(
      (c) => ((c.children[0] as TextContent).fontWeight = 500),
    )
    const buildWithUsContact = getText(startPage.buildWithUs[5])
    buildWithUsContact.fontWeight = 500

    const projectsTextHeading = getText(startPage.projectsText[0])
    projectsTextHeading.fontWeight = 700
    const projectsTextSmall = getText(startPage.projectsText[1])
    projectsTextSmall.fontWeight = 500

    startPage.projects.forEach((p) => {
      const projectHeading = getText(p.sections[1])
      projectHeading.fontWeight = 500
      const projectText = getText(p.sections[2])
      projectText.fontWeight = 800
    })

    getText(startPage.contact[1]).fontWeight = 700
    getText(startPage.contact[2]).fontWeight = 400
    getText(startPage.contact[4]).fontWeight = 700
    getText(startPage.contact[5]).fontWeight = 400
    getText(startPage.contact[7]).fontWeight = 700
    getText(startPage.contact[8]).fontWeight = 400

    const slogan = getText(footer.branding[1])
    slogan.fontWeight = 800
    const text = getText(footer.text[0])
    text.fontWeight = 400

    await updateDoc(startPageRef, startPage)
    await updateDoc(footerRef, footer)
  }
  console.log('Done!')
  console.groupEnd()
}

function getText(section: Section): TextContent {
  return section.children[0] as TextContent
}
