/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {ReactEditor, useSlate} from 'slate-react'
import {COLOR} from '../../../../../../src/styles/_color'
import {Section} from '../../../@types/Section'
import {SectionType} from '../../../@types/SectionType'
import {TextSection} from '../../../@types/TextSection'
import {Portal} from '../../Portal/Portal'
import {toolbarContainer} from '../toolbars.style'
import {TextSectionToolbar} from './TextSection/TextSectionToolbar'

interface Props {
  sections: Section[]
}

export function SectionToolbar({sections}: Props) {
  const editor = useSlate()
  const ref = useRef<HTMLDivElement | null>(null)

  const [opacity, setOpacity] = useState<0 | 1>(0)
  const [left, setLeft] = useState<number>(0)
  const [top, setTop] = useState<number>(0)

  const section = useMemo<Section | undefined>(() => {
    if (!!editor?.selection) {
      return sections[editor.selection.anchor.path[0]]
    }
  }, [editor.selection, sections])

  const show = useCallback((node: Node) => {
    const barEl = ref.current
    const rect = node.parentElement?.getBoundingClientRect()

    if (!!rect && !!barEl) {
      setLeft(
        rect.left + window.scrollX - barEl.getBoundingClientRect().width - 16,
      )
      setTop(rect.top + window.scrollY)
      setOpacity(1)
    }
  }, [])

  const hide = useCallback(() => {
    setTop(0)
    setLeft(0)
    setOpacity(0)
  }, [])

  const updatePosition = useCallback(() => {
    if (editor.selection && !!window) {
      const rootPoint = {
        path: [editor.selection.anchor.path[0], 0],
        offset: 0,
      }
      const [node] = ReactEditor.toDOMPoint(editor, rootPoint)

      if (node.nodeType === Node.TEXT_NODE) {
        show(node)
      }
    } else {
      hide()
    }
  }, [editor, editor.selection, hide, show])

  useEffect(() => {
    updatePosition()
  }, [updatePosition])

  return (
    <Portal>
      <div
        css={[
          toolbarContainer,
          css`
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
            opacity: ${opacity};
            color: ${COLOR.GREY};
            transform: translateX(${left}px) translateY(${top}px);
            gap: 2px;
          `,
        ]}
        style={{}}
        ref={ref}
      >
        {section?.type === SectionType.TEXT && (
          <TextSectionToolbar
            section={section as unknown as TextSection}
            onChange={() => updatePosition()}
          />
        )}
      </div>
    </Portal>
  )
}
