import React from 'react'
import {RenderElementProps, useReadOnly} from 'slate-react'
import {ButtonSection} from '../../@types/ButtonSection'
import {ImageRenderer} from '../../@types/ImageRenderer'
import {ImageSection} from '../../@types/ImageSection'
import {ListItemSection} from '../../@types/ListItemSection'
import {ListSection} from '../../@types/ListSection'
import {OnButtonSectionClick} from '../../@types/OnButtonSectionClick'
import {Section as SectionModel} from '../../@types/Section'
import {SectionType} from '../../@types/SectionType'
import {TextSection} from '../../@types/TextSection'
import {TextSectionType} from '../../@types/TextSectionType'
import {BlockQuote} from './BlockQuote'
import {Button} from './Button'
import {EditableImage} from './EditableImage'
import {H1} from './H1'
import {Heading} from './Heading'
import {Image} from './Image'
import {List} from './List'
import {ListItem} from './ListItem'
import {Paragraph} from './Paragraph'

interface Props extends RenderElementProps {
  element: SectionModel
  onButtonClick?: OnButtonSectionClick
  imageRenderer?: ImageRenderer
  imageClassName?: string
  imageWrapperClassName?: string
  listClassName?: string
  listItemClassName?: string
  textClassName?: string
  buttonClassName?: string
}

export function Section(props: Props) {
  const {
    element: section,
    children,
    attributes,
    imageRenderer,
    imageClassName,
    imageWrapperClassName,
    listClassName,
    listItemClassName,
    textClassName,
    buttonClassName,
    onButtonClick,
  } = props
  const readonly = useReadOnly()
  /*
  useEffect(() => {
    console.log('element:', section, attributes)
  }, [section])
*/

  switch (section.type) {
    case SectionType.BUTTON:
      return (
        <Button
          element={section as ButtonSection}
          onClick={onButtonClick as (id: string) => void}
          attributes={attributes}
          className={buttonClassName}
          readonly={readonly}
        >
          {children}
        </Button>
      )
    case SectionType.LIST:
      return (
        <List
          element={section as ListSection}
          attributes={attributes}
          className={listClassName}
        >
          {children}
        </List>
      )
    case SectionType.LIST_ITEM:
      return (
        <ListItem
          element={section as ListItemSection}
          attributes={attributes}
          className={listItemClassName}
        >
          {children}
        </ListItem>
      )
    case SectionType.IMAGE:
      return readonly ? (
        <Image
          element={section as ImageSection}
          attributes={attributes}
          imageRenderer={imageRenderer}
          className={imageClassName}
          imageWrapperClassName={imageWrapperClassName}
        >
          {children}
        </Image>
      ) : (
        <EditableImage
          element={section as ImageSection}
          attributes={attributes}
          imageRenderer={imageRenderer}
          className={imageClassName}
          imageWrapperClassName={imageWrapperClassName}
        >
          {children}
        </EditableImage>
      )
    default: {
      const textSection = section as TextSection
      switch (textSection.textType) {
        case TextSectionType.H1:
          return (
            <H1
              element={section as TextSection}
              attributes={attributes}
              className={textClassName}
            >
              {children}
            </H1>
          )
        case TextSectionType.HEADING:
          return (
            <Heading
              element={section as TextSection}
              attributes={attributes}
              className={textClassName}
            >
              {children}
            </Heading>
          )
        case TextSectionType.BLOCK_QUOTE:
          return (
            <BlockQuote
              element={section as TextSection}
              attributes={attributes}
              className={textClassName}
            >
              {children}
            </BlockQuote>
          )
        default:
          return (
            <Paragraph
              element={section as TextSection}
              attributes={attributes}
              className={textClassName}
            >
              {children}
            </Paragraph>
          )
      }
    }
  }
}
