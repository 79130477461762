@use '../../styles/color';
@use '../../styles/layout';

.container {
  display: flex;

  @include layout.phoneMax {
    display: none;
  }

  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
  font-weight: 600;

  a {
    color: black;
    text-decoration: none;

    &:not(:last-child) {
      margin-right: 10%;
    }

    &.active {
      color: color.$grey;
    }
  }
}
