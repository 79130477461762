import {Model} from '@joelssonbygg/shared/types/Model'
import {useCallback, useEffect} from 'react'
import {useAdmin} from '../../../lib/cms/src/services/admin/Admin.context'
import {
  PathOf,
  TypeOf,
  useForm,
} from '../../../lib/cms/src/services/form/useForm'
import {ContentsApi} from '../api/Contents.api'
import {useAuth} from '../auth/auth'

type OnChange<T extends Model> = <F extends PathOf<T>>(
  f: F,
  s: TypeOf<T, F>,
) => void

export function useContentAdmin<T extends Model>(id: string) {
  const {object: editable, isDirty, isValid, update, reset} = useForm<T>()
  const {isSignedIn} = useAuth()

  const save = useCallback(async () => {
    if (isSignedIn && !!editable && isDirty) {
      await ContentsApi.save(id, editable)
      reset()
    }
  }, [isSignedIn, isDirty, id, editable, reset])

  const cancel = useCallback(async () => {
    window.location.reload()
  }, [])

  const {setSaveEnabled, setCancelEnabled} = useAdmin(id, save, cancel)

  const loadContent = useCallback(async () => {
    const _content = await ContentsApi.get<T>(id)
    if (!!_content && !editable) {
      reset(_content)
    }
  }, [id])
  useEffect(() => void loadContent(), [loadContent])

  const onChange = useCallback<OnChange<T>>(
    (field, sections) => isSignedIn && update(field, sections),
    [isSignedIn, update],
  )

  useEffect(() => {
    setSaveEnabled(isDirty && isValid)
  }, [isDirty, isValid, setSaveEnabled])

  useEffect(() => {
    setCancelEnabled(isDirty)
  }, [isDirty, setCancelEnabled])

  return {editable, onChange}
}
