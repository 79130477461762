import classNames from 'classnames'
import * as React from 'react'
import {useNavigate} from 'react-router-dom'
import {Editor} from '../../../lib/cms/src'
import {fontWeights} from '../../services/admin/fontWeights'
import {useContentAdmin} from '../../services/admin/useContentAdmin'
import {useAuth} from '../../services/auth/auth'
import {HeaderContent} from '../../types/HeaderContent'
import {Nav} from '../Nav/Nav'
import * as s from './Header.module.scss'

export function Header() {
  const {isSignedIn} = useAuth()
  const {editable, onChange} = useContentAdmin<HeaderContent>('header')
  const navigate = useNavigate()

  return (
    <header className={classNames(s.header, {[s.signedIn]: isSignedIn})}>
      <div className={s.content}>
        {!!editable && (
          <Editor
            readOnly={!isSignedIn}
            sections={editable.logo}
            imageClassName={s.img}
            imageWrapperClassName={s.imgWrapper}
            onChange={(v) => onChange('logo', v)}
            className={s.logoContainer}
            fontWeights={fontWeights}
          />
        )}
        <Nav />
        {!!editable && (
          <Editor
            readOnly={!isSignedIn}
            sections={editable.contactUs}
            onChange={(v) => onChange('contactUs', v)}
            fontWeights={fontWeights}
            buttonClassName={s.contactUsButton}
            onButtonClick={() => navigate('#kontakt')}
          />
        )}
      </div>
    </header>
  )
}
