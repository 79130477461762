/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import classNames from 'classnames'
import React, {useMemo} from 'react'
import {RenderElementProps} from 'slate-react'
import {isEmpty} from 'voca'
import {ImageRenderer} from '../../@types/ImageRenderer'
import {ImageSection} from '../../@types/ImageSection'
import {TextContent} from '../../@types/TextContent'
import {useGrid} from '../../services/section/useGrid'

interface Props extends Omit<RenderElementProps, 'element'> {
  element: ImageSection
  imageRenderer?: ImageRenderer
  className?: string
  imageWrapperClassName?: string
}

export function Image(props: Props) {
  const {
    element: section,
    imageRenderer,
    className,
    imageWrapperClassName,
    attributes,
  } = props
  const grid = useGrid(section)

  const text = useMemo(() => {
    const _text = (section.children[0] as TextContent)?.text
    if (!isEmpty(_text)) {
      return _text
    }
  }, [section])

  const {image} = section

  return (
    <div
      className={classNames(imageWrapperClassName, section.id)}
      css={[
        css`
          position: relative;
          width: 100%;
          height: 100%;
        `,
        grid,
      ]}
      contentEditable={false}
    >
      {!image?.url ? (
        <div
          className={className}
          css={css`
            background-color: transparent;
          `}
          contentEditable={false}
        />
      ) : !!imageRenderer ? (
        imageRenderer(image)
      ) : (
        <img
          src={image.url}
          title={text ?? image.alt}
          alt={image.alt ?? text}
          className={className}
          contentEditable={false}
        />
      )}
      {!isEmpty((section.children[0] as TextContent)?.text) && (
        <span
          css={css`
            grid-column: 3 / 5;
            height: 90%;
          `}
          {...attributes}
        >
          {section.children}
        </span>
      )}
    </div>
  )
}
