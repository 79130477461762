/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import React, {useCallback, useMemo} from 'react'
import {createEditor} from 'slate'
import {withHistory} from 'slate-history'
import {
  Editable,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from 'slate-react'
import {EditableProps} from 'slate-react/dist/components/editable'
import {FontWeight} from '../../@types/FontWeight'
import {ImageRenderer} from '../../@types/ImageRenderer'
import {OnButtonSectionClick} from '../../@types/OnButtonSectionClick'
import {OnEditorChange} from '../../@types/OnEditorChange'
import {Section as SectionModel} from '../../@types/Section'
import {withImages} from '../../services/slate/withImages'
import {Section} from '../Section/Section'
import {Text} from '../Text/Text'
import {SectionToolbar} from '../Toolbars/Section/SectionToolbar'
import {SelectionToolbar} from '../Toolbars/Selection/SelectionToolbar'

interface Props<T extends SectionModel[]>
  extends Pick<EditableProps, 'as' | 'readOnly'> {
  sections: T
  onChange: OnEditorChange<T>
  className?: string
  imageRenderer?: ImageRenderer
  imageClassName?: string
  imageWrapperClassName?: string
  listClassName?: string
  listItemClassName?: string
  textClassName?: string
  buttonClassName?: string
  showSectionToolbar?: boolean
  fontWeights?: FontWeight[]
  onButtonClick?: OnButtonSectionClick
}

export function Editor<T extends SectionModel[] = SectionModel[]>({
  sections,
  onChange,
  className,
  imageRenderer,
  imageClassName,
  imageWrapperClassName,
  listClassName,
  listItemClassName,
  textClassName,
  buttonClassName,
  showSectionToolbar = false,
  fontWeights,
  onButtonClick,
  ...slateProps
}: Props<T>) {
  const editor = useMemo(
    () => withReact(withHistory(withImages(createEditor()))),
    [],
  )

  const renderLeaf = useCallback<(p: RenderLeafProps) => any>(
    ({children, ...props}) => {
      return <Text {...props}>{children}</Text>
    },
    [],
  )

  const renderElement = useCallback<(p: RenderElementProps) => any>(
    ({children, ...props}) => {
      return (
        <Section
          {...props}
          onButtonClick={onButtonClick}
          imageRenderer={imageRenderer}
          imageClassName={imageClassName}
          textClassName={textClassName}
          listClassName={listClassName}
          listItemClassName={listItemClassName}
          buttonClassName={buttonClassName}
          imageWrapperClassName={imageWrapperClassName}
        >
          {children}
        </Section>
      )
    },
    [],
  )

  // TODO: This shouldn't be needed. We need to be able to add sections and have slate pick it up
  if (!sections?.length) {
    return null
  }

  return (
    <Slate
      editor={editor}
      value={sections}
      onChange={(sections) => onChange(sections as T)}
    >
      {!slateProps.readOnly && showSectionToolbar && (
        <SectionToolbar sections={sections} />
      )}
      {!slateProps.readOnly && (
        <SelectionToolbar sections={sections} fontWeights={fontWeights} />
      )}
      <Editable
        className={className}
        css={[
          css`
            position: relative;
            align-items: center;
          `,
          slateProps.readOnly &&
            css`
              a {
                cursor: text;
              }
            `,
        ]}
        renderLeaf={renderLeaf}
        renderElement={renderElement}
        onClick={(e) => e.preventDefault()}
        {...slateProps}
        /*      onKeyDown={(event) => {
          if (!event.ctrlKey) {
            return
          }
          switch (event.key) {
            case '`': {
              event.preventDefault()
              const [match] = Editor.nodes<Section>(editor, {
                match: (n) => n.type === 'code',
              })
              Transforms.setNodes(
                editor,
                {type: match ? null : 'code'},
                {match: (n) => Editor.isBlock(editor, n)},
              )
              break
            }
            case 'b': {
              event.preventDefault()
              Transforms.setNodes<TextContent>(
                editor,
                {format: {bold: true}},
                {match: (n) => Text.isText(n), split: true},
              )
              break
            }
          }
        }}
  */
      />
    </Slate>
  )
}
