import React from 'react'
import {Editor} from '../../../lib/cms/src'
import {fontWeights} from '../../services/admin/fontWeights'
import {useContentAdmin} from '../../services/admin/useContentAdmin'
import {useAuth} from '../../services/auth/auth'
import {FooterContent} from '../../types/FooterContent'
import * as s from './Footer.module.scss'

export function Footer() {
  const {isSignedIn} = useAuth()
  const {editable, onChange} = useContentAdmin<FooterContent>('footer')

  return (
    <footer className={s.container}>
      <div className={s.content}>
        {!!editable && (
          <Editor
            readOnly={!isSignedIn}
            sections={editable.branding}
            imageWrapperClassName={s.imgWrapper}
            imageClassName={s.img}
            onChange={(v) => onChange('branding', v)}
            className={s.branding}
            fontWeights={fontWeights}
          />
        )}
        {!!editable && (
          <Editor
            readOnly={!isSignedIn}
            sections={editable.text}
            onChange={(v) => onChange('text', v)}
            className={s.text}
          />
        )}
      </div>
    </footer>
  )
}
