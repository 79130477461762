@use '../../styles/layout';

.container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 100px;
  background-color: black;
}

.content {
  display: grid;
  grid-template-columns: [branding] 1fr [text] 1fr;
  @include layout.contentWidth;
  @include layout.horizontalContentPadding;
  @include layout.phoneMax {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.branding {
  display: flex;
  flex-direction: column;
  p {
    color: white;
    font-size: 80px;

    @include layout.phoneMax {
      font-size: 50px;
    }
  }

  @include layout.phoneMax {
    width: 100%;
  }
}

.text {
  display: flex;

  @include layout.tabletMin {
    justify-content: center;
  }

  * {
    color: white;
  }
}

.imgWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  @include layout.phoneMax {
    padding-bottom: 25px;
  }
}

.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
