import {css} from '@emotion/react'
import {COLOR} from '../../../../../src/styles/_color'

export const toolbarContainerBorderRadius = '4px'
export const toolbarContainerBackgroundColor = 'black'

export const toolbarContainer = css`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: 4px 8px;
  background-color: ${toolbarContainerBackgroundColor};
  border-radius: ${toolbarContainerBorderRadius};
  opacity: 1;
  transition: opacity 250ms, transform 250ms;
`

export const toolbarButton = css`
  position: relative;
  display: flex;
  z-index: 2;
  justify-content: center;
  align-items: center;
  height: 20px;
  padding: 2px 6px 0;
  margin: 0 2px;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  color: ${COLOR.GREY_DARK};
  font-size: 16px;
  font-weight: 600;
  transition: color 250ms;
  border: none;

  img {
    width: 16px;
    height: 16px;
    filter: invert(69%) sepia(2%) saturate(1174%) hue-rotate(155deg)
      brightness(92%) contrast(84%);
  }

  &:hover {
    color: ${COLOR.GREY_LIGHT};
  }
`

export const activeToolbarButton = css`
  color: white;
  img {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(103deg)
      brightness(105%) contrast(105%);
  }
  &:hover {
    color: white;
  }
`
