@use '../../../styles/color';
@use '../../../styles/layout';

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @include layout.contentWidth;
  max-width: 1200px !important;
}

.content {
  position: relative;
  width: 100%;
  height: 60vh;

  @include layout.phoneMax {
    display: flex;
    flex-direction: column;
  }
}

.imgWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @include layout.tabletMin {
    border-radius: 8px;
  }

  @include layout.phoneMax {
    flex: 2;
  }
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text {
  line-height: 1;

  @include layout.tabletMin {
    position: absolute;
    bottom: 10%;
    left: 7%;
    z-index: 2;
    width: 60%;
    font-size: 80px;
  }

  @include layout.phoneMax {
    flex: 1;
    vertical-align: center;
    padding-top: 25px;
    margin-top: 0;
    background-color: color.$blue;
    font-size: 56px;
    display: flex;
    align-items: center;
    @include layout.fullContentPadding;
  }
}
