/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useSlate} from 'slate-react'
import {COLOR} from '../../../../../../../src/styles/_color'
import {FontWeight} from '../../../../@types/FontWeight'
import DownArrow from '../../../../assets/icons/streamline-icon-arrow-button-down-2@10x10.svg'
import {TextService} from '../../../../services/text/text.service'
import {activeToolbarButton, toolbarButton} from '../../toolbars.style'
import {buttonWrapper, subButtonsContainer} from '../SelectionToolbar.style'
import {FontWeightButton} from './FontWeightButton'

interface Props {
  weights?: FontWeight[]
}

export function FontWeightSelect({
  weights = [100, 200, 300, 400, 500, 600, 700, 800, 900],
}: Props) {
  const editor = useSlate()
  const [isBold, setIsBold] = useState<boolean>(TextService.isBold(editor))
  const [activeWeight, setActiveWeight] = useState<FontWeight>(
    TextService.getFormat(editor, 'fontWeight') ?? 400,
  )
  const [optionsVisible, setOptionsVisible] = useState<boolean>(false)

  const updateState = useCallback(() => {
    setIsBold(TextService.isBold(editor))
    setActiveWeight(TextService.getFormat(editor, 'fontWeight') ?? 400)
  }, [editor])

  useEffect(() => updateState(), [editor.selection])

  const updateFontWeight = useCallback<(weight: FontWeight) => void>(
    (weight) => {
      TextService.setFormat(editor, 'fontWeight', weight)
      updateState()
    },
    [editor, updateState],
  )

  const focusTimeout = useRef<number>()
  const blurTimeout = useRef<number>()

  const onBoldButtonHover = useCallback(() => {
    clearTimeout(blurTimeout.current)
    clearTimeout(focusTimeout.current)
    focusTimeout.current = setTimeout(() => {
      setOptionsVisible(true)
    }, 500)
    return () => clearTimeout(focusTimeout.current)
  }, [])

  const onOptionsHover = useCallback(() => {
    clearTimeout(blurTimeout.current)
    clearTimeout(focusTimeout.current)
  }, [])

  const onBlur = useCallback(() => {
    clearTimeout(blurTimeout.current)
    clearTimeout(focusTimeout.current)
    blurTimeout.current = setTimeout(() => {
      setOptionsVisible(false)
    }, 500)
    return () => clearTimeout(blurTimeout.current)
  }, [])

  return (
    <div css={buttonWrapper}>
      <button
        onMouseOver={onBoldButtonHover}
        onFocus={onBoldButtonHover}
        onMouseOut={onBlur}
        css={[toolbarButton, isBold && activeToolbarButton]}
        onClick={(e) => {
          e.preventDefault()
          updateFontWeight(isBold ? 400 : 700)
        }}
      >
        <b>B</b>
        <img
          src={DownArrow}
          alt="Down arrow"
          css={css`
            width: 6px !important;
            height: 6px !important;
            object-fit: contain;
            color: ${isBold ? 'white' : COLOR.GREY_DARK};
            right: -2px;
            position: absolute;
          `}
        />
      </button>
      {optionsVisible && (
        <ul
          css={subButtonsContainer}
          onMouseOut={onBlur}
          onBlur={onBlur}
          onMouseOver={onOptionsHover}
          onFocus={onOptionsHover}
        >
          {weights.includes(100) && (
            <li>
              <FontWeightButton
                weight={100}
                activeWeight={activeWeight}
                onClick={updateFontWeight}
              />
            </li>
          )}
          {weights.includes(200) && (
            <li>
              <FontWeightButton
                weight={200}
                activeWeight={activeWeight}
                onClick={updateFontWeight}
              />
            </li>
          )}
          {weights.includes(300) && (
            <li>
              <FontWeightButton
                weight={300}
                activeWeight={activeWeight}
                onClick={updateFontWeight}
              />
            </li>
          )}
          {weights.includes(400) && (
            <li>
              <FontWeightButton
                weight={400}
                activeWeight={activeWeight}
                onClick={updateFontWeight}
              />
            </li>
          )}
          {weights.includes(500) && (
            <li>
              <FontWeightButton
                weight={500}
                activeWeight={activeWeight}
                onClick={updateFontWeight}
              />
            </li>
          )}
          {weights.includes(600) && (
            <li>
              <FontWeightButton
                weight={600}
                activeWeight={activeWeight}
                onClick={updateFontWeight}
              />
            </li>
          )}
          {weights.includes(700) && (
            <li>
              <FontWeightButton
                weight={700}
                activeWeight={activeWeight}
                onClick={updateFontWeight}
              />
            </li>
          )}
          {weights.includes(800) && (
            <li>
              <FontWeightButton
                weight={800}
                activeWeight={activeWeight}
                onClick={updateFontWeight}
              />
            </li>
          )}
          {weights.includes(900) && (
            <li>
              <FontWeightButton
                weight={900}
                activeWeight={activeWeight}
                onClick={updateFontWeight}
              />
            </li>
          )}
        </ul>
      )}
    </div>
  )
}
