import React from 'react'
import {SignIn} from '../../components/SignIn/SignIn'
import {useAuth} from '../../services/auth/auth'
import * as s from './AdminPage.module.scss'

export function AdminPage() {
  const {isSignedIn} = useAuth()

  return (
    <article className={s.container}>
      {!isSignedIn && (
        <>
          <h1>Logga in</h1>
          <SignIn />
        </>
      )}
    </article>
  )
}
