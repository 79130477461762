import {initStartPage} from './initStartPage'

export async function updateBuildWithUs() {
  try {
    console.group('updateBuildWithUs')
    await initStartPage(['buildWithUs'])
  } catch (e) {
    console.error(e)
    throw e
  } finally {
    console.groupEnd()
  }
}
