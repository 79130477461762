import React, {MouseEventHandler, useCallback, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useAdmin} from '../../../lib/cms/src/services/admin/Admin.context'
import {useAuth} from '../../services/auth/auth'
import * as s from './AdminBar.module.scss'

export function AdminBar() {
  const {signOut, isDev} = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState<boolean>(false)
  const {saveEnabled, cancelEnabled, saving, save, cancel} = useAdmin()

  const _signOut = useCallback<MouseEventHandler<HTMLButtonElement>>(
    async (e) => {
      e.preventDefault()
      try {
        setLoading(true)
        await signOut()
        if (location.pathname.startsWith('/admin/dev')) {
          navigate('/')
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    },
    [signOut],
  )

  const _save = useCallback<MouseEventHandler<HTMLButtonElement>>(async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      await save()
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [])

  const _cancel = useCallback<MouseEventHandler<HTMLButtonElement>>((e) => {
    e.preventDefault()
    try {
      setLoading(true)
      cancel()
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <aside className={s.container}>
      <div className={s.content}>
        <button
          onClick={_save}
          title="Spara ändringar"
          disabled={loading || saving || !saveEnabled}
        >
          Spara
        </button>
        <button
          onClick={_cancel}
          title="Ta bort alla ändringar"
          disabled={loading || saving || !cancelEnabled}
        >
          Avbryt
        </button>
        {isDev && (
          <button
            onClick={(e) => {
              e.preventDefault()
              navigate('/admin/dev')
            }}
            disabled={loading || saving}
          >
            Developer
          </button>
        )}
        <button
          className={s.signOut}
          onClick={_signOut}
          title="Logga ut"
          disabled={loading || saving}
        >
          Logga ut
        </button>
      </div>
    </aside>
  )
}
