import {useCallback, useEffect} from 'react'
import {inTreeOfId} from './dom.utils'

export function useOutsideClick(
  containerId: string,
  callback: () => any,
  listenerPredicate?: () => boolean,
) {
  const onClick = useCallback(
    (e: Event) => {
      const el = e.target as HTMLElement
      if (!inTreeOfId(el, containerId)) {
        callback()
      }
    },
    [containerId],
  )

  useEffect(() => {
    if (!listenerPredicate || listenerPredicate()) {
      document.addEventListener('click', onClick)
      return () => {
        document.removeEventListener('click', onClick)
      }
    }
    return
  }, [listenerPredicate])
}
