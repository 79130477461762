@use 'sass:color';
@use '../../styles/layout';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include layout.contentWidth;
  height: 100%;
  padding-bottom: layout.$headerHeight;
  gap: 1rem;
}

.error {
  color: red;
}

.button {
  padding: 1rem 1.5rem;
  background-color: #deeeee;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: color.scale(#deeeee, $blackness: 2%);
  }
}
