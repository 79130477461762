import * as React from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

type SaveCallback = () => Promise<void>
type Save = () => Promise<void>
type CancelCallback = () => void
type Cancel = () => void

export interface AdminContext {
  save: Save
  setSaveCallback: (id: string, cb: SaveCallback) => void
  saving: boolean
  saveEnabled: boolean
  setSaveEnabled: (enabled: boolean) => void
  cancel: Cancel
  setCancelCallback: (id: string, cb: CancelCallback) => void
  cancelEnabled: boolean
  setCancelEnabled: (enabled: boolean) => void
  clear: (id: string) => void
}

export const AdminContext = createContext<AdminContext>({} as AdminContext)

export function AdminProvider({children}: any) {
  const saveCallbacks = useRef<{[key: string]: SaveCallback}>({})
  const cancelCallbacks = useRef<{[key: string]: CancelCallback}>({})
  const [saving, setSaving] = useState<boolean>(false)
  const [saveEnabled, setSaveEnabled] = useState<boolean>(false)
  const [cancelEnabled, setCancelEnabled] = useState<boolean>(false)

  const save = useCallback(async () => {
    setSaving(true)
    try {
      await Promise.all(
        Object.values(saveCallbacks.current).map(async (cb) => await cb()),
      )
    } finally {
      console.log('setting saving false')
      setSaving(false)
    }
  }, [])

  const cancel = useCallback(() => {
    Object.values(cancelCallbacks.current).forEach((cb) => cb())
  }, [])

  const setSaveCallback = useCallback((id: string, cb: SaveCallback) => {
    saveCallbacks.current[id] = cb
  }, [])

  const setCancelCallback = useCallback(
    (id: string, cb: CancelCallback) => (cancelCallbacks.current[id] = cb),
    [],
  )

  const clear = useCallback((id: string) => {
    delete saveCallbacks.current[id]
    delete cancelCallbacks.current[id]
  }, [])

  return (
    <AdminContext.Provider
      value={{
        save,
        setSaveCallback,
        saving,
        saveEnabled,
        setSaveEnabled,
        cancel,
        setCancelCallback,
        cancelEnabled,
        setCancelEnabled,
        clear,
      }}
    >
      {children}
    </AdminContext.Provider>
  )
}

export function useAdmin(
  id?: string,
  onSave?: SaveCallback,
  onCancel?: CancelCallback,
): AdminContext {
  const context = useContext<AdminContext>(AdminContext)

  useEffect(() => {
    if (!!onSave && !!id) {
      context.setSaveCallback(id, onSave)
    }
  }, [id, onSave])

  useEffect(() => {
    if (!!onCancel && !!id) {
      context.setCancelCallback(id, onCancel)
    }
  }, [id, onCancel])

  return context
}
