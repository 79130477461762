/** @jsxImportSource @emotion/react */
import classNames from 'classnames'
import React, {useCallback, useEffect, useMemo, useRef} from 'react'
import {useDropzone} from 'react-dropzone'
import {Path} from 'slate'
import {ReactEditor, RenderElementProps, useSlate} from 'slate-react'
import {isEmpty} from 'voca'
import {FileContentType} from '../../@types/FileContentType'
import {Image} from '../../@types/Image'
import {ImageRenderer} from '../../@types/ImageRenderer'
import {ImageSection} from '../../@types/ImageSection'
import {TextContent} from '../../@types/TextContent'
import Upload from '../../assets/icons/streamline-icon-upload-thick-bottom@40x40.svg'
import {getImageDimensions} from '../../services/image/getImageDimensions'
import {useGrid} from '../../services/section/useGrid'
import {
  editableImageCaption,
  editableImageContainer,
  editableImageDragging,
  editableImageDraggingIndicator,
  editableImageImage,
  editableImageUpload,
  editableImageUploadDragging,
} from './EditableImage.style'
import {SectionService} from './Section.service'

interface Props extends Omit<RenderElementProps, 'element'> {
  element: ImageSection
  imageRenderer?: ImageRenderer
  className?: string
  imageWrapperClassName?: string
  // onChange: (image: Image) => any
}

export function EditableImage({
  element: section,
  imageRenderer,
  className,
  imageWrapperClassName,
  attributes,
}: Props) {
  const {image} = section
  const editor = useSlate()
  const path = useRef<Path>()
  const grid = useGrid(section)

  const text = useMemo(() => {
    const _text = (section.children[0] as TextContent)?.text
    if (!isEmpty(_text)) {
      return _text
    }
  }, [section])

  useEffect(() => {
    const _path = ReactEditor.findPath(editor, section)
    path.current = _path
  }, [editor, section])

  const onDrop = useCallback((files: File[]) => {
    files.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = async () => {
        // Do whatever you want with the file contents
        const url = reader.result as string
        const {width, height} = await getImageDimensions(url)
        const image: Image = {
          url,
          fileName: file.name,
          height,
          width,
        }
        editor.setFragmentData
        SectionService.setImage(editor, image, path.current as Path)
      }
      reader.readAsDataURL(file)
    })
  }, [])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: Object.values(FileContentType),
  })

  return (
    <div
      {...getRootProps({
        className: classNames(imageWrapperClassName, section.id),
        contentEditable: false,
        css: [editableImageContainer, grid],
      })}
    >
      <input {...getInputProps()} contentEditable={false} />
      {!image?.url ? (
        <div
          className={className}
          css={[
            editableImageUpload,
            isDragActive && editableImageUploadDragging,
          ]}
          contentEditable={false}
        >
          <img src={Upload} alt="Upload" contentEditable={false} />
          <span contentEditable={false}>
            Drop file here, or click to select
          </span>
        </div>
      ) : !!imageRenderer ? (
        imageRenderer(image, className)
      ) : (
        <>
          <img
            src={image.url}
            title={text ?? image.alt}
            alt={image.alt ?? text}
            className={className}
            css={editableImageImage}
            contentEditable={false}
          />
          <div
            contentEditable={false}
            css={[
              editableImageDraggingIndicator,
              isDragActive && editableImageDragging,
            ]}
          />
        </>
      )}
      {!isEmpty((section.children[0] as TextContent)?.text) && (
        <span css={editableImageCaption} {...attributes}>
          {section.children}
        </span>
      )}
    </div>
  )
}
