@use '../../../styles/layout';

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @include layout.contentWidth;
  @include layout.phoneMax {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.content {
  position: relative;
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: [image] 80px [text] auto;
  width: 100%;
  row-gap: 1rem;

  @include layout.phoneMax {
    display: flex;
    flex-direction: column;
  }
}

.img {
  width: auto;
  max-width: 80px;
  max-height: 80px;
  object-fit: contain;
}

.imgWrapper {
  display: flex;
  justify-content: center;
  grid-row: image;
}

.text {
  grid-row: text;
  text-align: center;

  @include layout.phoneMax {
    padding-bottom: 75px;
  }
}
