@use '../../../styles/layout';
@use '../../../styles/color';

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: color.$blue;
}

.content {
  position: relative;
  display: grid;
  justify-content: flex-start;
  align-items: center;
  grid-template-columns: [image-1] auto [text-1] 2fr [image-2] auto [text-2] 2fr [image-3] auto [text-3] 2fr;
  grid-template-rows: [heading] auto [value] auto;
  @include layout.contentWidth;
  padding: 0 10%;
  column-gap: 1rem;

  @include layout.phoneMax {
    display: flex;
    flex-direction: column;
    p {
      padding-bottom: 75px;
    }
  }

  .imgWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-row: 1/-1;
    max-width: 80px;
    max-height: 80px;

    &:nth-child(1) {
      grid-column: image-1;
    }
    &:nth-child(4) {
      grid-column: image-2;
    }
    &:nth-child(7) {
      grid-column: image-3;
    }
  }

  .img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  h2 {
    margin-bottom: 0;
    font-size: 22px;
  }

  .text {
    padding-right: 1rem;
    color: white;

    &:nth-child(2) {
      grid-column: text-1;
      grid-row: heading;
    }
    &:nth-child(3) {
      grid-column: text-1;
      grid-row: value;
    }
    &:nth-child(5) {
      grid-column: text-2;
      grid-row: heading;
    }
    &:nth-child(6) {
      grid-column: text-2;
      grid-row: value;
    }
    &:nth-child(8) {
      grid-column: text-3;
      grid-row: heading;
    }
    &:nth-child(9) {
      grid-column: text-3;
      grid-row: value;
    }
  }
}
