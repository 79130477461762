/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import classNames from 'classnames'
import React from 'react'
import {RenderElementProps} from 'slate-react'
import {TextSection} from '../../@types/TextSection'
import {useGrid} from '../../services/section/useGrid'

interface Props extends RenderElementProps {
  element: TextSection
  className?: string
}

export function BlockQuote(props: Props) {
  const {element: section, children, attributes, className} = props
  const {textAlign} = section
  const grid = useGrid(section)

  return (
    <blockquote
      css={[
        css`
          align-self: center;
          padding: 2rem 4rem;
          margin: auto 0;
          text-align: ${textAlign};
          font-size: 1.5rem;
          font-style: italic;
          line-height: 1.3;

          &:before {
            background: transparent !important;
          }
        `,
        grid,
      ]}
      className={classNames(className, section.id)}
      {...attributes}
    >
      {children}
    </blockquote>
  )
}
