@use '../../../styles/color';
@use '../../../styles/layout';

:root {
  --swiper-theme-color: #275d38;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @include layout.contentWidth;
  overflow-x: auto;
  overflow-y: hidden;

  @include layout.phoneMax {
    width: calc(
      100% - #{layout.$paddingHorizontal} - #{layout.$paddingHorizontal}
    );
  }
}

.next,
.previous {
  position: absolute;
  top: 135px;
  z-index: 1000;
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: white;
  border: none;
  cursor: pointer;
  transition: background-color 200ms;

  img {
    width: 15px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
}

.next {
  right: 0;
  padding-right: 10px;
  padding-left: 15px;
}

.previous {
  left: 0;
  padding-right: 15px;
  padding-left: 10px;
}

.swiper {
  width: 100%;
  height: 340px;
  padding-bottom: 80px;
}

.addContainer {
  button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;

    img {
      height: 20px;
      padding-right: 10px;
    }
  }
}

.removing {
  position: absolute;
  z-index: 2000;
  width: 100%;
  height: 100%;
  background-color: color.$grey-lightest;

  .track,
  .progress {
    position: absolute;
    top: calc(50% - 4px);
    left: 40%;
    width: 20%;
    height: 8px;
  }

  .track {
    background-color: color.$grey-light;
  }

  .progress {
    background-color: color.$blue;
    transition: max-width 100ms;
  }
}
