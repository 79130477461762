@use '../../../styles/color';
@use '../../../styles/layout';

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @include layout.contentWidth;
  //@include layout.phoneMax {
  //  padding-bottom: 2rem;
  //}
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @include layout.phoneMax {
    @include layout.horizontalContentPadding;
    text-align: center;

    h2 {
      padding-bottom: 25px;
    }
  }
}
