import React, {useCallback, useMemo, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useForm} from '../../../lib/cms/src/services/form/useForm'
import {useAuth} from '../../services/auth/auth'
import * as s from './SignIn.module.scss'

interface SignInValues {
  email: string
  password: string
}
const empty = {email: '', password: ''}

export function SignIn() {
  const {signIn} = useAuth()
  const {object: values, isDirty, update} = useForm<SignInValues>(empty)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const navigate = useNavigate()

  const _signIn = useCallback(async () => {
    setLoading(true)
    try {
      await signIn(values.email, values.password)
      navigate('/')
    } catch {
      setError('Inloggningen misslyckades')
    } finally {
      setLoading(false)
    }
  }, [signIn, values.email, values.password])

  const submitDisabled = useMemo(
    () =>
      loading ||
      !isDirty ||
      values.password.length < 6 ||
      !values.email.includes('@') ||
      !values.email.includes('.'),
    [isDirty, values.email, values.password, loading],
  )

  const [submitEnableOverride, setSubmitEnableOverride] =
    useState<boolean>(false)

  const focus = useCallback<(el: HTMLInputElement) => any>((el) => {
    if (!!el) {
      el.focus()
      setTimeout(() => {
        if (el?.matches(':-internal-autofill-selected')) {
          setSubmitEnableOverride(true)
          buttonRef.current?.focus()
        }
      }, 500)
    }
  }, [])

  return (
    <form
      className={s.form}
      onSubmit={(e) => {
        e.preventDefault()
        _signIn()
      }}
    >
      <input
        ref={focus}
        id="sign-in-email"
        type="email"
        placeholder="Email"
        onChange={(e) => update('email', e.target.value)}
        value={values.email}
        disabled={loading}
        autoComplete="username"
      />
      <input
        type="password"
        placeholder="Password"
        onChange={(e) => update('password', e.target.value)}
        value={values.password}
        disabled={loading}
        autoComplete="current-password"
      />
      <button
        ref={buttonRef}
        type="submit"
        className="button"
        disabled={submitDisabled && !submitEnableOverride}
      >
        {loading ? 'Loggar in...' : 'Logga in'}
      </button>
      {!!error && <div>{error}</div>}
    </form>
  )
}
