import { Model } from '@joelssonbygg/shared/types/Model'
import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  WithFieldValue,
} from 'firebase/firestore'

export function typeTransformer<T extends Model>(): FirestoreDataConverter<T> {
  return {
    toFirestore: (model: WithFieldValue<T>): DocumentData => model,
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>): T =>
      ({ id: snapshot.id, ...snapshot.data() } as T),
  }
}
