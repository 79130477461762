.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6000;
  width: 0;
  height: 0;
  transition: backdrop-filter 250ms;
}

.show {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(4px);
}
