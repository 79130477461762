/** @jsxImportSource @emotion/react */
import React, {useMemo, useRef, useState} from 'react'
import {useSlate} from 'slate-react'
import uniqid from 'uniqid'
import {Section} from '../../../../../@types/Section'
import {SectionType} from '../../../../../@types/SectionType'
import {TextSection} from '../../../../../@types/TextSection'
import {TextSectionType} from '../../../../../@types/TextSectionType'
import ArrowDown from '../../../../../assets/icons/streamline-icon-arrow-button-down-2@10x10.svg'
import {useOutsideClick} from '../../../../../services/dom/useOutsideClick'
import {SectionService} from '../../../../Section/Section.service'
import {activeToolbarButton} from '../../../toolbars.style'
import {button, container, listButton} from './TextSectionTypeSelect.style'

interface Props {
  section: TextSection
  onChange: () => any
}

export function TextSectionTypeSelect({section, onChange}: Props) {
  const editor = useSlate()
  const [opened, setOpened] = useState<boolean>(false)
  const containerId = useRef(uniqid()).current

  useOutsideClick(
    containerId,
    () => setOpened(false),
    () => opened,
  )

  const label = useMemo<string>(() => {
    if (!!section?.type) {
      return getLabelForType(section)
    }
    return ''
  }, [section?.type, section?.textType])

  return (
    <>
      <button
        css={[button, opened && activeToolbarButton]}
        onClick={() => setOpened(!opened)}
        id={containerId}
      >
        <span>{label}</span>
        <img src={ArrowDown} alt="Arrow down" />
        {opened && (
          <ul css={container}>
            {Object.values(TextSectionType)
              .filter((t) => t !== section?.textType)
              .map((t) => (
                <li key={t}>
                  <button
                    css={[button, listButton]}
                    onClick={() => {
                      SectionService.setTextSectionType(editor, t)
                      onChange()
                    }}
                  >
                    {getLabelForTextType(t)}
                  </button>
                </li>
              ))}
          </ul>
        )}
      </button>
    </>
  )
}

export function getLabelForTextType(textType: TextSectionType | undefined) {
  switch (textType) {
    case TextSectionType.BLOCK_QUOTE:
      return 'q'
    case TextSectionType.H1:
      return 'h1'
    case TextSectionType.HEADING:
      return 'h2'
    default:
      return 'p'
  }
}

function getLabelForType(section: Section): string {
  switch (section.type) {
    case SectionType.LIST:
    case SectionType.LIST_ITEM:
      return 'List'
    case SectionType.IMAGE:
      return 'Img'
    case SectionType.TEXT:
      return getLabelForTextType((section as TextSection).textType)
  }
}
