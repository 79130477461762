/** @jsxImportSource @emotion/react */
import React from 'react'
import {RenderElementProps} from 'slate-react'
import {ListItemSection} from '../../@types/ListItemSection'

interface Props extends Omit<RenderElementProps, 'element'> {
  element: ListItemSection
  className?: string
}

export function ListItem(props: Props) {
  const {element: section, className, children, attributes} = props
  const {textAlign} = section

  return (
    <li style={{textAlign: textAlign}} className={className} {...attributes}>
      {children}
    </li>
  )
}
