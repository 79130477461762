import { Editor, Element, Path, Transforms } from 'slate'
import { Image } from '../../@types/Image'
import { ImageSection } from '../../@types/ImageSection'
import { SectionType } from '../../@types/SectionType'
import { TextSection } from '../../@types/TextSection'
import { TextSectionType } from '../../@types/TextSectionType'

const { isElement } = Element

export const SectionService = {
  setTextSectionType(editor: Editor, toType: TextSectionType): void {
    Transforms.setNodes<TextSection>(
      editor,
      { textType: toType },
      { match: isElement },
    )
  },

  setTextSectionAlign(
    editor: Editor,
    textAlign: TextSection['textAlign'],
  ): void {
    Transforms.setNodes<TextSection>(
      editor,
      { textAlign },
      {
        match: (n) => isElement(n) && n.type === SectionType.TEXT,
      },
    )
  },

  setImage(editor: Editor, image: Image, path: Path) {
    Transforms.setNodes<ImageSection>(editor, { image }, { at: path })
  },
}
