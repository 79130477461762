import React from 'react'
import {Editor} from '../../../../lib/cms/src'
import {fontWeights} from '../../../services/admin/fontWeights'
import {useAuth} from '../../../services/auth/auth'
import {StartPageContent} from '../../../types/StartPageContent'
import * as s from './Hero.module.scss'

interface Props {
  sections: StartPageContent['hero']
  onChange: (v: this['sections']) => any
}

export function Hero({sections, onChange}: Props) {
  const {isSignedIn} = useAuth()

  return (
    <section className={s.container}>
      {!!sections && (
        <Editor
          readOnly={!isSignedIn}
          className={s.content}
          imageClassName={s.img}
          imageWrapperClassName={s.imgWrapper}
          textClassName={s.text}
          sections={sections}
          onChange={onChange}
          as="div"
          fontWeights={fontWeights}
        />
      )}
    </section>
  )
}
