/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import classNames from 'classnames'
import React from 'react'
import {RenderElementProps} from 'slate-react'
import {TextSection} from '../../@types/TextSection'
import {useGrid} from '../../services/section/useGrid'

interface Props extends RenderElementProps {
  element: TextSection
  className?: string
}

export function Paragraph(props: Props) {
  const {element: section, children, attributes, className} = props
  const {textAlign} = section
  const grid = useGrid(section)

  return (
    <p
      css={[
        css`
          margin-top: 0;
          font-weight: 200;
          text-align: ${textAlign};
        `,
        grid,
      ]}
      className={classNames(className, section.id)}
      {...attributes}
    >
      {children}
    </p>
  )
}
