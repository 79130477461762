// Import the functions you need from the SDKs you need
import {Analytics, getAnalytics as _getAnalytics} from 'firebase/analytics'
import {FirebaseApp, getApps, initializeApp} from 'firebase/app'
import {Auth, connectAuthEmulator, getAuth as _getAuth} from 'firebase/auth'
import {
  connectFirestoreEmulator,
  Firestore,
  getFirestore as _getFirestore,
} from 'firebase/firestore'
import {
  connectFunctionsEmulator,
  Functions,
  getFunctions as _getFunctions,
} from 'firebase/functions'
import {
  connectStorageEmulator,
  FirebaseStorage,
  getStorage as _getStorage,
} from 'firebase/storage'

export let app: FirebaseApp
let auth: Auth
let functions: Functions
let db: Firestore
let storage: FirebaseStorage
let analytics: Analytics

if (!getApps().length) {
  app = initializeApp({
    apiKey: 'AIzaSyBHl2znAc03-79E3vYGrh_o_W790cZHnqI',
    authDomain: 'joelssonbygg-prod.firebaseapp.com',
    projectId: 'joelssonbygg-prod',
    storageBucket: 'joelssonbygg-prod.appspot.com',
    messagingSenderId: '455047956740',
    appId: '1:455047956740:web:fe03c85fcc39f06bf22851',
    measurementId: 'G-QNLJTY46P4',
  })
  console.debug('firebase initialized')
}

const isDev = process.env.NODE_ENV === 'development'

export function initAnalytics() {
  if (!analytics && !isDev) {
    analytics = _getAnalytics(app)
  }
}

export function getDb(): Firestore {
  if (!db) {
    db = _getFirestore()
    if (isDev) {
      console.debug('connecting to firestore emulator')
      connectFirestoreEmulator(db, 'localhost', 8080)
    }
  }
  return db
}

export function getFunctions(): Functions {
  if (!functions) {
    functions = _getFunctions()
    if (isDev) {
      console.debug('connecting to functions emulator')
      connectFunctionsEmulator(functions, 'localhost', 5001)
    }
  }
  return functions
}

export function getAuth(): Auth {
  if (!auth) {
    auth = _getAuth()
    if (isDev) {
      console.debug('connecting to auth emulator')
      connectAuthEmulator(auth, 'http://localhost:9099')
    }
  }
  return auth
}

export function getStorage(): FirebaseStorage {
  if (!storage) {
    storage = _getStorage()
    if (isDev) {
      console.debug('connecting to storage emulator')
      connectStorageEmulator(storage, 'localhost', 9199)
    }
  }
  return storage
}
