import { getDoc } from 'firebase/firestore'
import { LinkContent } from '../../../lib/cms/src/@types/LinkContent'
import { SectionType } from '../../../lib/cms/src/@types/SectionType'
import { TextContent } from '../../../lib/cms/src/@types/TextContent'
import { TextContentType } from '../../../lib/cms/src/@types/TextContentType'
import { TextSectionType } from '../../../lib/cms/src/@types/TextSectionType'
import { FooterContent } from '../../types/FooterContent'
import { ContentsApi } from '../api/Contents.api'
import { createImageSection } from '../section/createImageSection'
import { createTextSection } from '../section/createTextSection'
import { typedDoc } from './typedDoc'

export async function initFooter() {
  console.group('Footer')
  const ref = typedDoc<FooterContent>(ContentsApi.COLLECTION, 'footer')
  const snap = await getDoc(ref)

  let hasChanges = false
  let footer: FooterContent
  if (snap.exists()) {
    console.debug('Footer exists, checking if any of its content is missing...')
    footer = snap.data()
  } else {
    console.debug('Creating Footer...')
    footer = { id: 'footer' } as FooterContent
  }

  if (!!footer.branding) {
    console.debug('Footer -> Branding already exists. Skipping.')
  } else {
    hasChanges = true
    console.debug('Footer -> Creating branding...')
    footer.branding = createBranding()
  }

  if (!!footer.text) {
    console.debug('Footer -> Text already exists. Skipping.')
  } else {
    hasChanges = true
    console.debug('Footer -> Creating text...')
    footer.text = createText()
  }

  if (hasChanges) {
    console.debug('Persisting changes to Header...')
    console.log(footer)
    await ContentsApi.save('footer', footer)
  }

  console.debug('Header done!')
  console.groupEnd()
  return
}

function createBranding(): FooterContent['branding'] {
  return [
    createImageSection({ id: 'branding-logo' }),
    createTextSection('Bygg\nInredning & Entrepenad', {
      id: 'branding-slogan',
      format: {
        fontWeight: 900,
      },
    }),
  ]
}

function createText(): FooterContent['text'] {
  return [
    {
      id: 'text',
      textType: TextSectionType.PARAGRAPH,
      type: SectionType.TEXT,
      children: [
        {
          text: 'Joelsson Bygg\nLäby Österby 103\n755 92 Uppsala\n\n',
          type: TextContentType.TEXT,
        } as TextContent,
        {
          text: 'Bygg:',
          type: TextContentType.TEXT,
          bold: true,
        } as TextContent,
        {
          text: '\nGöran Joelsson\n072-2439085\n',
          type: TextContentType.TEXT,
        } as TextContent,
        {
          text: 'goran@joelssonbygg.se',
          type: TextContentType.LINK,
          url: 'mailto:goran@joelssonbygg.se',
          external: true,
          openInNewTab: true,
        } as LinkContent,
        {
          text: '\n\n',
          type: TextContentType.TEXT,
        } as TextContent,
        {
          text: 'Administration:',
          type: TextContentType.TEXT,
          bold: true,
        } as TextContent,
        {
          text: '\nPer Carlsson\n070-1903450\n',
          type: TextContentType.TEXT,
        } as TextContent,
        {
          text: 'per@joelssonbygg.se',
          type: TextContentType.LINK,
          url: 'mailto:per@joelssonbygg.se',
          external: true,
          openInNewTab: true,
        } as LinkContent,
      ],
    },
  ]
}
