import React, {useState} from 'react'
import {Editor} from '../../../../../lib/cms/src'
import TrashIcon from '../../../../assets/icons/streamline-icon-bin-1@40x40.svg'
import {fontWeights} from '../../../../services/admin/fontWeights'
import {useAuth} from '../../../../services/auth/auth'
import {ProjectContent} from '../../../../types/ProjectContent'
import * as s from './Project.module.scss'

interface Props {
  project: ProjectContent
  onRemove: (project: ProjectContent) => void
  onChange: (v: ProjectContent) => any
}

export function Project({project, onChange, onRemove}: Props) {
  const {isSignedIn} = useAuth()
  const [removed, setRemoved] = useState(false)

  if (removed) {
    return null
  }

  return !!project ? (
    <div className={s.container}>
      {isSignedIn && (
        <button
          className={s.removeButton}
          onClick={(e) => {
            e.preventDefault()
            onRemove(project)
            setRemoved(true)
          }}
        >
          <img src={TrashIcon} alt="Knappikon för att ta bort projekt" />
        </button>
      )}
      <Editor
        readOnly={!isSignedIn}
        className={s.content}
        sections={project.sections}
        imageWrapperClassName={s.imgWrapper}
        imageClassName={s.img}
        textClassName={s.text}
        onChange={(s) => onChange({sections: s})}
        as="div"
        fontWeights={fontWeights}
      />
    </div>
  ) : null
}
