@use '../../../styles/color';
@use '../../../styles/layout';

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @include layout.contentWidth;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @include layout.horizontalContentPadding;

  @include layout.phoneMax {
    text-align: center;
  }
}
