import classNames from 'classnames'
import * as React from 'react'
import {useMemo} from 'react'
import {Link, useLocation} from 'react-router-dom'
import * as s from './Nav.module.scss'

type Hash = '' | '#tjanster' | '#projekt' | '#kontakt'

export function Nav() {
  const {pathname, hash} = useLocation()

  const active = useMemo<Hash | null>(
    () => (pathname === '/' ? (hash as Hash) : null),
    [pathname, hash],
  )

  return (
    <nav className={s.container}>
      <Link
        to="/"
        title="Hem"
        className={classNames({[s.active]: active === ''})}
      >
        Hem
      </Link>
      <Link
        to="/#tjanster"
        title="Tjänster"
        className={classNames({[s.active]: active === '#tjanster'})}
      >
        Tjänster
      </Link>
      <Link
        to="/#projekt"
        title="Projekt"
        className={classNames({[s.active]: active === '#projekt'})}
      >
        Projekt
      </Link>
      <Link
        to="/#kontakt"
        title="Kontakt"
        className={classNames({[s.active]: active === '#kontakt'})}
      >
        Kontakt
      </Link>
    </nav>
  )
}
