import { Editor } from 'slate'
import { SectionType } from '../../@types/SectionType'

export function withImages(editor: Editor) {
  const { isVoid } = editor

  editor.isVoid = (element) =>
    element.type === SectionType.IMAGE ? true : isVoid(element)

  /*
  editor.insertData = (data) => {
    const text = data.getData('text/plain')
    const { files } = data

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader()
        const [mime] = file.type.split('/')

        if (mime === 'image') {
          reader.addEventListener('load', () => {
            const url = reader.result
            insertImage(editor, url)
          })

          reader.readAsDataURL(file)
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text)
    } else {
      insertData(data)
    }
  }
*/

  return editor
}
