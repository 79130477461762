import { FileContentType } from '../../@types/FileContentType'

export function getFileContentTypeFromExtension(
  urlOrFileName: string,
): FileContentType | undefined {
  const end =
    urlOrFileName.indexOf('?') >= 0 ? urlOrFileName.indexOf('?') : undefined
  const start = urlOrFileName.lastIndexOf('.')
  const extension = urlOrFileName.substring(start, end)
  switch (extension) {
    case '.jpg':
    case '.jpeg':
      return FileContentType.JPG
    case '.gif':
      return FileContentType.GIF
    case '.png':
      return FileContentType.PNG
    case '.webp':
      return FileContentType.WEBP
    case '.svg':
      return FileContentType.SVG
  }
}
