import {isEqual} from 'lodash'
import {cloneDeep, get, set} from 'lodash/fp'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Get, ReadonlyDeep} from 'type-fest'
import uniqid from 'uniqid'

export type PathOf<T> = Extract<keyof T, string> | string
export type TypeOf<T, P extends PathOf<T>> = unknown extends Get<T, P>
  ? unknown
  : Get<T, P>

export type Errors<T, P extends PathOf<T> = PathOf<T>> = {
  [key in P]: true | string
}
export type Validator<T, P extends PathOf<T>> = (
  value: TypeOf<T, P>,
) => boolean | string
type MappedValidators<T, P extends PathOf<T>> = {
  [key in P]: Validator<T, P> | Validator<T, P>[]
}

export interface Form<T extends object> {
  id: string
  object: T
  reset: (object?: T) => void
  revert: () => void
  dirtyPaths: Set<PathOf<T>>
  isDirty: boolean
  errors: Errors<T>
  hasError: (path?: PathOf<T>) => boolean
  addError: (path: PathOf<T>, message?: string) => void
  removeError: (path: PathOf<T>) => void
  update: <P extends PathOf<T>>(path: P, value: TypeOf<T, P>) => void
  updateObject: (object: T) => void
  validate: () => Errors<T>
  isValid: boolean
  setValidator: <P extends PathOf<T>>(
    path: P,
    fn: Validator<T, P> | Validator<T, P>[],
  ) => void
  focus: (path: PathOf<T>) => void
  blur: (path: PathOf<T>) => void
  focused: PathOf<T> | null
  touchedPaths: Set<PathOf<T>>
}

export function useForm<T extends object>(original?: T): Form<T> {
  const _original = useRef<ReadonlyDeep<T>>(original as ReadonlyDeep<T>)
  const id = useRef(uniqid()).current
  const [object, setObject] = useState<Form<T>['object']>(
    _original.current as T,
  )
  const [errors, setErrors] = useState<Form<T>['errors']>({} as Errors<T>)
  const [mappedValidators] = useState<MappedValidators<T, any>>(
    {} as MappedValidators<T, any>,
  )
  const [dirtyPaths, setDirtyPaths] = useState<Set<PathOf<T>>>(new Set())
  const [focused, setFocused] = useState<PathOf<T> | null>(null)
  const [touchedPaths, setTouchedPaths] = useState<Set<PathOf<T>>>(new Set())

  const isTouched = useMemo<boolean>(() => !!touchedPaths.size, [touchedPaths])

  const addError = useCallback<Form<T>['addError']>(
    (field, message) => {
      if (!Object.keys(errors).includes(String(field))) {
        const _errors = {...errors, [field]: message ?? true}
        setErrors(_errors)
      }
    },
    [errors],
  )

  const removeError = useCallback<Form<T>['removeError']>(
    (field) => {
      const _errors = {...errors}
      delete _errors[field]
      setErrors(_errors)
    },
    [errors],
  )

  const hasError = useCallback<(path?: PathOf<T>) => boolean>(
    (path) => {
      if (!!path) {
        return Object.keys(errors).includes(path)
      }
      return !!errors.length
    },
    [errors],
  )

  const isDirty = useMemo(() => {
    return !!dirtyPaths.size
  }, [dirtyPaths])

  const update = useCallback<Form<T>['update']>(
    (path, value) => {
      const currentValue = get(path, object)
      if (value !== currentValue) {
        const _updated = set(path, value, object)
        setObject(_updated)
        const originalValue = get(path, _original.current)
        if (!isEqual(value, originalValue)) {
          setDirtyPaths(new Set([...dirtyPaths, path]))
        } else {
          dirtyPaths.delete(path)
          setDirtyPaths(new Set([...dirtyPaths]))
        }
      }
    },
    [dirtyPaths, object],
  )

  const updateObject = useCallback<Form<T>['updateObject']>(
    (_object) =>
      // TODO: Should be recursive in order to update deep
      Object.entries(_object).forEach(([key, value]) => update(key, value)),
    [update],
  )

  const setValidator = useCallback<Form<T>['setValidator']>(
    (path, fn) => {
      mappedValidators[path] = fn
    },
    [mappedValidators],
  )

  const validate = useCallback<Form<T>['validate']>(() => {
    const _errors: Errors<T> = {} as Errors<T>
    Object.entries(mappedValidators).forEach(([path, validatorFn]) => {
      const value = get(path, object)
      const validatorFnArray = Array.isArray(validatorFn)
        ? validatorFn
        : [validatorFn]
      const result = validatorFnArray.reduce<boolean | string>((result, fn) => {
        const r = fn(value)
        if (
          typeof r === 'string' ||
          (r === false && typeof result !== 'string')
        ) {
          result = r
        }
        return result
      }, true)

      if (result === false || typeof result === 'string') {
        _errors[path as PathOf<T>] = typeof result === 'string' ? result : true
      }
    })
    setErrors(_errors)
    return _errors
  }, [object, mappedValidators])

  const isValid = useMemo(() => !Object.keys(errors).length, [errors])

  useEffect(() => {
    if (isTouched) {
      validate()
    }
  }, [isTouched, object, validate])

  const reset = useCallback<Form<T>['reset']>(
    (_object) => {
      if (!!_object) {
        _original.current = _object as ReadonlyDeep<T>
        setObject(_object)
      } else {
        _original.current = object as ReadonlyDeep<T>
      }
      setErrors({} as Errors<T>)
      setDirtyPaths(new Set())
      setTouchedPaths(new Set())
    },
    [object],
  )

  const revert = useCallback<Form<T>['revert']>(() => {
    setObject(cloneDeep(_original.current) as T)
    setErrors({} as Errors<T>)
    setDirtyPaths(new Set())
    setTouchedPaths(new Set())
  }, [])

  const focus = useCallback<Form<T>['focus']>((path) => {
    setFocused(path)
  }, [])

  const blur = useCallback<Form<T>['blur']>(
    (path) => {
      setFocused(null)
      setTouchedPaths(new Set([...touchedPaths, path]))
    },
    [touchedPaths],
  )

  useEffect(() => {
    validate()
  }, [])

  return {
    id,
    object,
    updateObject,
    reset,
    revert,
    dirtyPaths,
    isDirty,
    errors,
    hasError,
    addError,
    removeError,
    update,
    validate,
    setValidator,
    isValid,
    focus,
    blur,
    focused,
    touchedPaths,
  }
}
