import { initFooter } from './initFooter'
import { initHeader } from './initHeader'
import { initStartPage } from './initStartPage'

export async function initDb() {
  try {
    console.group('initDb')
    await initHeader()
    await initStartPage()
    await initFooter()
  } catch (e) {
    console.error(e)
    throw e
  } finally {
    console.groupEnd()
  }
}
