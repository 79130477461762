import React, {useLayoutEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {useContentAdmin} from '../../services/admin/useContentAdmin'
import {useAuth} from '../../services/auth/auth'
import {StartPageContent} from '../../types/StartPageContent'
import {BuildWithUs} from './BuildWithUs/BuildWithUs'
import {Businesses} from './Businesses/Businesses'
import {Contact} from './Contact/Contact'
import {Hero} from './Hero/Hero'
import {Lead} from './Lead/Lead'
import {Projects} from './Projects/Projects'
import {ProjectsText} from './ProjectsText/ProjectsText'

export function StartPage() {
  const {editable, onChange} = useContentAdmin<StartPageContent>('startPage')
  const {hash} = useLocation()
  const {isSignedIn} = useAuth()

  // TODO Fix initial scrolling (timeout fires before element is rendered)
  useLayoutEffect(() => {
    if (hash === '') {
      window.scrollTo({top: 0, behavior: 'smooth'})
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          let headerOffset = 90
          if (isSignedIn) {
            headerOffset += 40
          }
          const elementPosition = element.getBoundingClientRect().top
          const offsetPosition = elementPosition + window.scrollY - headerOffset
          window.scrollTo({top: offsetPosition, behavior: 'smooth'})
        }
      }, 0)
    }
  }, [hash, isSignedIn])

  if (!editable) {
    return null
  }

  return (
    <>
      <Hero sections={editable.hero} onChange={(v) => onChange('hero', v)} />
      <Lead sections={editable.lead} onChange={(v) => onChange('lead', v)} />

      <Businesses
        sections={editable.businesses}
        onChange={(v) => onChange('businesses', v)}
      />
      <BuildWithUs
        sections={editable.buildWithUs}
        onChange={(v) => onChange('buildWithUs', v)}
      />

      <ProjectsText
        sections={editable.projectsText}
        onChange={(v) => onChange('projectsText', v)}
      />
      <Projects
        projects={editable.projects}
        onChange={(v) => onChange('projects', v)}
      />

      <Contact
        sections={editable.contact}
        onChange={(v) => onChange('contact', v)}
      />
    </>
  )
}
